import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import {Link} from 'react-router-dom';
import PaginationFirestore from './pagination/PaginationFirestore';

class SearchList extends React.PureComponent {
    constructor (props) {
        super(props)
    }

    render() {
        const { 
            results, 
            value, 
            highlightedIndex, 
            itemProps,
            dataLoaded,
            showLoadMore,
            noResults,
            usersSearchLimit,
            getPaginationUsers,
            lastVisibleDoc
        } = this.props;

        return (
            <div id="search-list">
                <ul>
                    {(value && results.length > 0) ? (
                        <React.Fragment>                        
                            {results.map((result, index) => {
                                if (!result.isEmpty) {
                                    const isSelected = highlightedIndex === index
                                    return (
                                        <ListItem
                                        selected={isSelected}
                                        key={result.userID}
                                        dense
                                        className="search-list-item"
                                        {...itemProps({
                                            item: result
                                        })}
                                        >
                                            <Link to={`/user/${result.userID}`} >                      
                                                <Avatar src={result.photo} />                                           
                                            </Link>
                                            <ListItemText 
                                            primary={<Link to={`/user/${result.userID}`} >{result.name}</Link>}
                                            />
                                        </ListItem>
                                    )
                                } else {
                                    return (
                                        <ListItem
                                        key={result.userID}
                                        dense
                                        className="search-list-item"
                                        >
                                            <ListItemText 
                                            primary={result.name}
                                            />
                                        </ListItem>
                                    )
                                }
                            })}
                            {(dataLoaded && showLoadMore && !noResults) && (
                                <PaginationFirestore 
                                items={results} 
                                itemsLimit={usersSearchLimit}
                                lastVisibleDoc={lastVisibleDoc}
                                paginationData={getPaginationUsers} 
                                showLoadMore={showLoadMore}
                                dense={true}
                                />
                            )}
                            <ListItem className="search-list-item">
                                Výsledky pro {value}
                            </ListItem>
                        </React.Fragment>
                    ) : (
                        value && (
                            <ListItem className="search-list-item">
                                Výsledky pro {value}
                            </ListItem>
                        )
                    )}                    
                </ul>
            </div>
        )
    }    
}

export default SearchList;