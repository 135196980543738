import React from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import Menu from '@material-ui/icons/Menu';
import Home from '@material-ui/icons/Home';
import Around from '@material-ui/icons/ThreeSixty';
import Join from '@material-ui/icons/PersonAdd';
import Login from '@material-ui/icons/ExitToApp';
import Centrums from '@material-ui/icons/FitnessCenter';
import Clubs from '@material-ui/icons/Store';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { isUserLoggedIn } from '../../actions/usersActions';
import { connect } from 'react-redux';

class NavigationMobile extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            openMenu: false
        }

        this.handleClose = this.handleClose.bind(this);
        this.handleMenu = this.handleMenu.bind(this);
    }

    handleMenu (event) {
        this.setState({ 
            anchorEl: event.currentTarget,
            openMenu: true
        });
    };
    
    handleClose() {
        this.setState({ 
            anchorEl: null,
            openMenu: false 
        });
    };

    render() {
        const { openMenu } = this.state;
        const isLoggedIn = isUserLoggedIn();

      return (
        <div className="header__mobile">
            <IconButton 
            onClick={this.handleMenu}
            color='inherit'
            disableRipple={true}
            disableTouchRipple={true}
            className='header__mobile-button'
            >
                <Menu fontSize="large" />
            </IconButton>
            <Drawer
                className="mobile__user-menu"
                anchor="left"
                open={openMenu}
                onClose={this.handleClose}
                >
                    <ListItem className="mobile__user-menu-item" >
                        <h3 className="mobile__user-menu-item-logo">SportyFind</h3>
                    </ListItem>
                    <ListItem className="mobile__user-menu-item" onClick={this.handleClose} component={NavLink} activeClassName="active-mobile-link" exact={true} to='/'>
                        <ListItemIcon>
                            <Home />
                        </ListItemIcon>
                        <ListItemText inset primary='Home' />
                    </ListItem>
                    <ListItem className="mobile__user-menu-item" onClick={this.handleClose} component={NavLink} activeClassName="active-mobile-link" exact={true} to='/sports'>
                        <ListItemIcon>
                            <Around />
                        </ListItemIcon>
                        <ListItemText inset primary='Navštívit' />
                    </ListItem>
                    <ListItem className="mobile__user-menu-item" onClick={this.handleClose} component={NavLink} activeClassName="active-mobile-link" exact={true} to='/users-sports'>
                        <ListItemIcon>
                            <Join />
                        </ListItemIcon>
                        <ListItemText inset primary='Zahrát si' />
                    </ListItem>
                    <ListItem className="mobile__user-menu-item" onClick={this.handleClose} component={NavLink} activeClassName="active-mobile-link" exact={true} to='/centrums'>
                        <ListItemIcon>
                            <Centrums />
                        </ListItemIcon>
                        <ListItemText inset primary='Sportovní centra' />
                    </ListItem>
                    <ListItem className="mobile__user-menu-item" onClick={this.handleClose} component={NavLink} activeClassName="active-mobile-link" exact={true} to='/clubs'>
                        <ListItemIcon>
                            <Clubs />
                        </ListItemIcon>
                        <ListItemText inset primary='Sportovní kluby' />
                    </ListItem>
                    {!isLoggedIn && (
                        <ListItem className="mobile__user-menu-item" onClick={this.handleClose} component={NavLink} activeClassName="active-mobile-link" exact={true} to='/login'>
                            <ListItemIcon>
                                <Login />
                            </ListItemIcon>
                            <ListItemText inset primary='Přihlášení' />
                        </ListItem>   
                    )}                 
                </Drawer>
        </div>
      )
    }
}

export default withRouter(connect()(NavigationMobile));