export const SET_CENTRUM_FILTER = 'SET_CENTRUM_FILTER';
export const SET_CENTRUM_RADIUS_FILTER = 'SET_CENTRUM_RADIUS_FILTER';
export const SET_CENTRUM_DETAIL_ADDRESS_FILTER = 'SET_CENTRUM_DETAIL_ADDRESS_FILTER';
export const SET_CENTRUMS_DISTANCE = 'SET_CENTRUMS_DISTANCE';
export const SET_CENTRUMS_ORDER = 'SET_CENTRUMS_ORDER';

export const setCentrumRadiusFilter = ( radius = 0 ) => ({
    type: SET_CENTRUM_RADIUS_FILTER,
    radius
});

export const setCentrumFilter = ( centrum ) => ({
    type: SET_CENTRUM_FILTER,
    centrum
});

export const setCentrumDetailAddressFilter = ( address = { name: '', longitude: 0, latitude: 0, zoom: 6 } ) => ({
    type: SET_CENTRUM_DETAIL_ADDRESS_FILTER,
    address
});

export const setCentrumsDistance = ( centrumDistance = 0 ) => ({
    type: SET_CENTRUMS_DISTANCE,
    centrumDistance
});

export const setCentrumsOrder = ( order = 'closest' ) => ({
    type: SET_CENTRUMS_ORDER,
    order
});