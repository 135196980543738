export const SET_CLUBS_SPORT_FILTER = 'SET_CLUBS_SPORT_FILTER';
export const SET_CLUBS_FULLTEXT_FILTER = 'SET_CLUBS_FULLTEXT_FILTER';
export const SET_CLUBS_LEAGUE_FILTER = 'SET_CLUBS_LEAGUE_FILTER';
export const SET_CLUBS_LEAGUES_FILTER = 'SET_CLUBS_LEAGUES_FILTER';

export const setClubsFulltextFilter = ( text = '' ) => ({
    type: SET_CLUBS_FULLTEXT_FILTER,
    text
});

export const setClubsSportFilter = ( sport ) => ({
    type: SET_CLUBS_SPORT_FILTER,
    sport
});

export const setClubsLeagueFilter = ( league ) => ({
    type: SET_CLUBS_LEAGUE_FILTER,
    league
})

export const setClubsLeaguesFilter = ( leagues = [] ) => ({
    type: SET_CLUBS_LEAGUES_FILTER,
    leagues
})