import React from 'react';
import { Provider } from 'react-redux';
import { firebase } from '../firebase/firebase';
import AppRouter from '../routers/AppRouter';
import { configureStore } from '../store/configureStore';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import ErrorBoundary from '../components/error_component/ErrorBoundary';
import { SnackbarProvider } from 'notistack';
import { dispatchLogin, 
        updateUserProfil, 
        startCreateUserInDB, 
        addPhone, 
        addMessenger } from '../actions/auth';
import { startGetMyUser, startGetMyUserPrivateInfo, isUserOnline } from '../actions/usersActions';
import { setUserAddressFilter } from '../actions/usersSportsFilterActions';
import LoadingPage from './LoadingPage';
import ReactGA from 'react-ga';

const store = configureStore;
const photoPlaceholder = 'https://firebasestorage.googleapis.com/v0/b/sportyfind-6ba19.appspot.com/o/users%2Fplaceholder%2Fuser-placeholder-final.png?alt=media&token=eb6e2614-8425-447b-9dfc-3339640b6f91'
const GA_CODE = 'UA-150276738-1';
const theme = createMuiTheme({    
    palette: {
        primary: {
            main: '#019964',
            contrastText: '#fff'
        },
        secondary: {
            main: '#0ba4f5',
            contrastText: '#fff'
        }
    },
    typography: {
        useNextVariants: true
    },
    breakpoints: {
        values: {
            sm: 800,
            md: 960,
            lg: 1280,
            xl: 1920
        }
    }
});

class App extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isAppRendered: false,
            online: window.navigator.onLine ? window.navigator.onLine : false
        }

        this._goOnline = this._goOnline.bind(this);
        this._goOffline = this._goOffline.bind(this);
        this._reRenderApp = this._reRenderApp.bind(this);
        this._handleNetwor = this._handleNetwor.bind(this);
    }

    componentDidMount() {
        this.mounted = true;

        const appElement = document.getElementById('app');

        // initialize google analytics
        ReactGA.initialize(GA_CODE);
        // subscribe realtime change
        this.authSubscription = firebase.auth().onAuthStateChanged((user) => {
            if (user) {      
                if (user.emailVerified) { 
                    startGetMyUser(user.uid).then((userData) => {             
                        if (userData.exists) {
                            if(user.email !== userData.data().email) {
                                updateUserProfil({
                                    email: user.email
                                })
                            }                
                            const userDataValue = userData.data();
            
                            store.dispatch(dispatchLogin(
                                user.uid,
                                userDataValue.name ? userDataValue.name : user.displayName, 
                                userDataValue.email ? userDataValue.email : user.email,
                                userDataValue.photo ? userDataValue.photo : photoPlaceholder,
                                user.providerData ? user.providerData : []
                            ));
            
                            this._reRenderApp();
                            
                            //get user private info
                            startGetMyUserPrivateInfo().then((userPrivateData) => {
                                if (userPrivateData.exists) {
                                    const phone = userPrivateData.data().phone;
                                    const messenger = userPrivateData.data().messenger;
            
                                    store.dispatch(addPhone(phone));
                                    store.dispatch(addMessenger(messenger));
                                }
                            }).catch((err) => {
                                let { stack } = err
                                stack = JSON.stringify(stack)
                                const params = err.getParams ? err.getParams() : {};

                                ReactGA.event({
                                    category: 'JS ERROR',
                                    action: 'Chyba pri nacitani privatnych dat uzivatela',
                                    label: `stack: ${stack}, params: ${JSON.stringify(params)}, userAgent: ${navigator.userAgent}`
                                })
                            })
                            
                        } else {             
                            let userPhoto = '';
                            if ( !!user.providerData.find(provider => provider.providerId === 'facebook.com')) {
                                userPhoto = user.photoURL + '?type=large';
                            } else {
                                userPhoto = user.photoURL ? user.photoURL : photoPlaceholder;
                            }
                            const userName = user.displayName ? user.displayName : 'Anonym'
                            startCreateUserInDB(user.uid, {
                                name: userName,
                                nameLower: userName.toLowerCase(),
                                email: user.email,
                                photo: userPhoto                                
                            }, {
                                createdAt: new Date().valueOf(),
                                phone: '',
                                messenger: '',
                                agreeTerms: true
                            }).then(() => {
                                store.dispatch(dispatchLogin(
                                    user.uid, userName, user.email, userPhoto, 
                                    user.providerData ? user.providerData : []
                                ));
                                ReactGA.event({
                                    category: 'JS SUCCESS',
                                    action: 'Uzivatel uspesne vytvoreny !',
                                    label: 'Success Login'
                                })
                                
                            }).catch((err) => {
                                let { stack } = err
                                stack = JSON.stringify(stack)
                                const params = err.getParams ? err.getParams() : {};

                                ReactGA.event({
                                    category: 'JS ERROR',
                                    action: 'Chyba pri vytvoreni uzivatela do DB',
                                    label: `stack: ${stack}, params: ${JSON.stringify(params)}, userAgent: ${navigator.userAgent}`
                                })
                            })
                        }
                        
                        this._reRenderApp();
                    }).catch((err) => {
                        this._reRenderApp();
                        let { stack } = err
                        stack = JSON.stringify(stack)
                        const params = err.getParams ? err.getParams() : {};

                        ReactGA.event({
                            category: 'JS ERROR',
                            action: 'Chyba pri nacitani uzivatela z DB',
                            label: `stack: ${stack}, params: ${JSON.stringify(params)}, userAgent: ${navigator.userAgent}`
                        })
                    }); 
                    
                    // set app margin
                    if (appElement) {
                        appElement.classList.add('user-login')
                    }
                } else {
                    this._reRenderApp();

                    if (appElement) {
                        appElement.classList.remove('user-login')
                    }
                }
            } else {      
                this._reRenderApp();

                if (appElement) {
                    appElement.classList.remove('user-login')
                }
            }
        });

        //set User address
        const addressLocalStorage = localStorage.getItem('userAddress');
        if (addressLocalStorage) {
            store.dispatch(setUserAddressFilter(JSON.parse(addressLocalStorage)));
        }

        window.addEventListener('online', this._goOnline);
        window.addEventListener('offline', this._goOffline);

        this._handleNetwor();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { isAppRendered: nowRender } = this.state;
        const { isAppRendered: nextRender } = nextState;
        
        return nowRender !== nextRender;
    }

    componentWillUnmount() {
        this.mounted = false;
        this.authSubscription();
        window.removeEventListener('online', this._goOnline);
        window.removeEventListener('offline', this._goOffline);
      }

    render() {
        const { isAppRendered } = this.state;

        if (isAppRendered) {
            return (
                <Provider store={store}>
                    <MuiThemeProvider theme={theme}>
                        <ErrorBoundary>
                            <SnackbarProvider 
                            preventDuplicate 
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}>
                                <AppRouter />
                            </SnackbarProvider>
                        </ErrorBoundary>
                    </MuiThemeProvider>        
                </Provider>   
            )
        } else {
            return (
                <LoadingPage />
            )
        }
    }

    _reRenderApp() {
        if (this.mounted) {
            this.setState({
                isAppRendered: true
            })
        }
    }

    _goOnline() {
        if (!this.state.online) {
          this.setState({ online: true });          
        }
        this._handleNetwor();        
    }

    _goOffline() {
        if (this.state.online) {
            this.setState({ online: false });            
        }
        this._handleNetwor();
    }

    _handleNetwor() {
        const { online } = this.state;

        store.dispatch(isUserOnline(online))
    }
}

export default App;