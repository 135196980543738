import ReactGA from 'react-ga';

if ('serviceWorker' in navigator) {
    if (__IS_PRODUCTION__) {
        window.addEventListener('load', () => registerSW());
    } else {
        window.addEventListener('load', () => unregister())
    }
}

function registerSW() {
    navigator.serviceWorker.register('/sw.js').then((registration) => {
        ReactGA.event({
            category: 'Service-Worker',
            action: 'Success',
            label: `Service worker nainstalovany, userAgent: ${navigator.userAgent}.`
        })
    }).catch(err => {
        let { stack } = err
        stack = JSON.stringify(stack)
        const params = err.getParams ? err.getParams() : {};

        ReactGA.event({
            category: 'Service-Worker',
            action: 'Chyba pri nastaveni service workera',
            label: `stack: ${stack}, params: ${JSON.stringify(params)}, userAgent: ${navigator.userAgent}`
        })
    })
}

function unregister() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(registration => {
        registration.unregister();
      });
    }
  }