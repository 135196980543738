import React from 'react';
import { Link } from 'react-router-dom';

const LogoMobile = () => (

    <div className="header__logo" >
        <Link to="/" >
            <img src="/images/logo/sf_logo.svg" alt="logo" className="header__logo-image" />   
        </Link>
    </div>

);

export default LogoMobile;