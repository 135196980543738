import React from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Home from '@material-ui/icons/Home';
import Notifications from '@material-ui/icons/Notifications';
import People from '@material-ui/icons/People';
import Search from '@material-ui/icons/Search';
import {Link} from 'react-router-dom';
import SearchDialogMobile from './SearchDialogMobile';
import Badge from '@material-ui/core/Badge';

class BottomUserNavigationMobile extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
            open: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.handleOpenSearchDialog = this.handleOpenSearchDialog.bind(this);
    }

    handleChange(event, value) {
        this.setState({
            value
        })
    }

    handleCloseDialog() {
        this.setState({
            open: false
        })
    }

    handleOpenSearchDialog() {
        this.setState({
            open: true
        })
    }

    render() {
        const { value, open } = this.state;
        const { friendNotificationLength, eventNotificationsLength } = this.props;

      return (
        <BottomNavigation value={value} className="header__user-bottom-navigation" onChange={this.handleChange}>
            <BottomNavigationAction component={Link} to="/" className="header__user-bottom-navigation-item" value="home" icon={<Home />} onClick={this.handleCloseDialog} />
            <BottomNavigationAction 
                component={Link}
                to='/profil/notifications'
                className="header__user-bottom-navigation-item" 
                value="notifications" 
                icon={<Badge 
                        badgeContent={eventNotificationsLength} 
                        invisible={eventNotificationsLength < 1} 
                        color="secondary"
                        max={eventNotificationsLength - 1}
                        >
                            <Notifications />
                        </Badge> } 
                onClick={this.handleCloseDialog} 
            />
            <BottomNavigationAction
                component={Link}
                to='/profil/friends-notifications'
                className="header__user-bottom-navigation-item" 
                value="friends" 
                icon={<Badge 
                        badgeContent={friendNotificationLength} 
                        invisible={friendNotificationLength < 1} 
                        color="secondary"
                        max={friendNotificationLength - 1}
                        >
                            <People />
                        </Badge> } 
                onClick={this.handleCloseDialog} 
            />
            <BottomNavigationAction className="header__user-bottom-navigation-item"  value="settings" icon={<Search />} onClick={this.handleOpenSearchDialog} />
            <SearchDialogMobile open={open} handleCloseDialog={this.handleCloseDialog} />
        </BottomNavigation>
      )
    }
}

export default BottomUserNavigationMobile;