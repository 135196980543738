import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from '../../../routers/AppRouter';
import Person from '@material-ui/icons/Person';
import Settings from '@material-ui/icons/Settings';
import LogOut from '@material-ui/icons/ExitToApp';
import Search from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import Notifications from '@material-ui/icons/Notifications';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Create from '@material-ui/icons/Create';
import People from '@material-ui/icons/People';
import PersonAdd from '@material-ui/icons/PersonAdd';
import Badge from '@material-ui/core/Badge';
import FriendNotificationsList from '../notifications/FriendNotificationList';
import NotificationsList from '../notifications/NotificationsList';
import { startLogout, dispatchLogout } from '../../../actions/auth';
import { searchUserName, 
        getSearchUsers, 
        searchUserNamePagination,
        resetSearchUsers,
        isUserLoggedIn, 
        startSetEventsNotifications, 
        startSetFriendNotifications } from '../../../actions/usersActions';
import SearchList from "../../SearchList";
import Hidden from '@material-ui/core/Hidden';
import BottomUserNavigationMobile from '../../mobile_components/BottomUserNavigationMobile';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import InputBase from '@material-ui/core/InputBase';
import { debounce } from '../../../helper_func/Debounce';
import Downshift from 'downshift';
import { notificationsLimit, usersSearchLimit } from '../../../constants/constants';
import { withSnackbar } from 'notistack';
import { showSnackbar } from '../../../helper_func/SnackBarcCallback';

const styles = {
    flexGrow: 1,
    textAlign: 'right'
}

export class UserLogin extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            openMenu: false,
            searchText: '',
            showFriends: false,
            showNotifications: false,
            showSearch: false,
            dataLoaded: false,
            lastVisibleDoc: undefined,
            showLoadMore: true,
            noResults: false
        }

        this.handleClose = this.handleClose.bind(this);
        this.handleMenu = this.handleMenu.bind(this);
        this._showFriends = this._showFriends.bind(this);
        this._handleFriendClose = this._handleFriendClose.bind(this);
        this.showSearchBar = this.showSearchBar.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSearch = debounce(this.handleSearch, 800); 
        this._handleNotificationsClose = this._handleNotificationsClose.bind(this);
        this._showNotifications = this._showNotifications.bind(this);
        this._handleSearchClose = this._handleSearchClose.bind(this);
        this.getPaginationUsers = this.getPaginationUsers.bind(this);
    }

    componentDidMount() {
        this.mounted = true;

        if (isUserLoggedIn()) {
            this.unsubEventsNotifications = this.props.dispatch(startSetEventsNotifications());
            this.unsubFriendsNotifications = this.props.dispatch(startSetFriendNotifications())
        }
    }

    componentWillUnmount() {
        this.mounted = false;

        if (this.unsubEventsNotifications) {
            this.unsubEventsNotifications()
        }

        if (this.unsubFriendsNotifications) {
            this.unsubFriendsNotifications()
        }
    }

    handleMenu (event) {
        this.setState({ 
            anchorEl: event.currentTarget,
            openMenu: true
        });
    };
    
    handleClose() {
        this.setState({ 
            anchorEl: null,
            openMenu: false 
        });
    };

    showNotifications() {
        const notificationsDiv = document.querySelector('.notifications-list');
        const style = window.getComputedStyle(notificationsDiv).getPropertyValue('display');        
        notificationsDiv.style.display = style === 'none' ? 'block' : 'none';
    }

    showSearchBar() {         
        this.setState(prevState => ({
            showSearch: !prevState.showSearch
        }))
    }

    _handleSearchClose() {
        this.setState({
            showSearch: false
        })
    }

    _showFriends() {
        this.setState(prevState => ({
            showFriends: !prevState.showFriends
        }))
    }

    _handleFriendClose(){
        this.setState({
            showFriends: false
        })
    }

    _showNotifications() {
        this.setState(prevState => ({
            showNotifications: !prevState.showNotifications
        }))
    }

    _handleNotificationsClose() {
        const { eventNotificationOpen } = this.props;

        if (!eventNotificationOpen) {
            this.setState({
                showNotifications: false
            })
        }
    }

    handleInputChange(value) {
        this.setState({
            searchText: value
        })
    }

    handleSearch(value) {  
        const { searchResults } = this.props;
        const inputValue = value.toLowerCase();

        if (searchResults.length > 0) {
            this.props.dispatch(resetSearchUsers());
        }
        
        if (inputValue) {
            searchUserName(inputValue).then((snapShot) => {
                if (this.mounted) {
                    this.setState({
                        showLoadMore: true,
                        noResults: false
                    })
                }

                if (!snapShot.empty) {
                    const lastDoc = snapShot.docs[snapShot.docs.length-1];
                    let usersData = [];
                    snapShot.forEach(function(data) {
                        usersData.push({
                            userID: data.id,
                            ...data.data()
                        })
                    });

                    if (this.mounted) {
                        this.setState({
                            lastVisibleDoc: lastDoc
                        })
                    }
    
                    this.props.dispatch(getSearchUsers(usersData));
                } else {
                    if (this.mounted) {
                        this.setState({
                            noResults: true
                        })
                    }

                    this.props.dispatch(getSearchUsers([{
                        name: `Žádné výsledky pro ${value}`,
                        isEmpty: true,
                        userID: 'empty'
                    }]))
                }

                if (this.mounted) {
                    this.setState({
                        dataLoaded: true
                    })
                }
            }).catch((err) => {
                showSnackbar(this.props.enqueueSnackbar, this.props.closeSnackbar, 'Nastala chyba při hledání !', 'error', err);
            })
        }        
    }    

    render() {
        const { userName, photoURL, friendNotifications, eventsNotifications } = this.props;
        const { openMenu } = this.state;
    
        return (   
            <div style={styles} className="header__user">
                <Link to='/profil' className="header__user-name">
                    { userName }
                </Link>
                <Hidden smUp>
                    <BottomUserNavigationMobile friendNotificationLength={friendNotifications.length} eventNotificationsLength={eventsNotifications.length} />
                </Hidden>
                <Hidden xsDown>
                    {this._renderDesktopUserMenu()}
                </Hidden>
                <IconButton                
                onClick={this.handleMenu}
                className="header__user-button">
                    <Avatar className="header__user-icon" src={ photoURL } />
                </IconButton>
                <Drawer
                id="header__user-menu"
                anchor="right"
                open={openMenu}
                onClose={this.handleClose}
                >
                    <ListItem className="header__user-menu-item" onClick={this.handleClose} component={Link} to='/profil'>
                        <ListItemIcon>
                            <Person />
                        </ListItemIcon>
                        <ListItemText inset primary='Profil' />
                    </ListItem>
                    <ListItem className="header__user-menu-item" onClick={this.handleClose} component={Link} to='/profil/create-event'>
                        <ListItemIcon>
                            <Create color="secondary" />
                        </ListItemIcon>
                        <ListItemText inset primary='Vytvořit událost' />
                    </ListItem>
                    <ListItem className="header__user-menu-item" onClick={this.handleClose} component={Link} to='/profil/friends'>
                        <ListItemIcon>
                            <People />
                        </ListItemIcon>
                        <ListItemText inset primary='Přátelé' />
                    </ListItem>
                    <ListItem className="header__user-menu-item" onClick={this.handleClose} component={Link} to='/profil/joined-events'>
                        <ListItemIcon>
                            <PersonAdd />
                        </ListItemIcon>
                        <ListItemText inset primary='Zúčastním se' />
                    </ListItem>
                    <ListItem className="header__user-menu-item" onClick={this.handleClose} component={Link} to='/profil/settings'>
                        <ListItemIcon>
                            <Settings />
                        </ListItemIcon>
                        <ListItemText inset primary='Nastavení' />
                    </ListItem>
                    <ListItem className="header__user-menu-item" onClick={() => {
                        startLogout().then(() => {
                            this.props.dispatch(dispatchLogout());
                            history.push('/login');
                        }).catch((err) => {
                            showSnackbar(this.props.enqueueSnackbar, this.props.closeSnackbar, 'Nastala chyba při odlášení !', 'error', err);
                        })
                    }}>
                        <ListItemIcon>
                            <LogOut />
                        </ListItemIcon>
                        <ListItemText inset primary='Odhlášení' />
                    </ListItem>
                </Drawer>            
            </div>     
        )
    }

    _renderDesktopUserMenu() {
        const { friendNotifications, userCurInfo, eventsNotifications } = this.props;
        const { showSearch, showFriends, showNotifications } = this.state;
        return (
            <React.Fragment>
                <div className="header__user-search">
                    <ClickAwayListener onClickAway={this._handleSearchClose}>
                        <div className="header__user-search--wrap">
                            <Tooltip TransitionComponent={Zoom} title='Vyhledat'>
                            <IconButton color="inherit"
                            onClick={this.showSearchBar}
                            >
                                <Search />
                            </IconButton>
                            </Tooltip>
                            {showSearch && (
                                this._renderSearchInput()
                            )}
                        </div>
                    </ClickAwayListener>
                </div>
                <div className="header__user-friends">
                    <ClickAwayListener onClickAway={this._handleFriendClose}>
                        <div>
                            <Tooltip TransitionComponent={Zoom} title='Žádosti o přátelství'>
                                <IconButton 
                                color="inherit"
                                onClick={this._showFriends}
                                >
                                    <Badge 
                                    color="secondary" 
                                    badgeContent={friendNotifications.length} 
                                    invisible={friendNotifications.length < 1}
                                    max={notificationsLimit - 1}
                                    >
                                        <People />
                                    </Badge>                    
                                </IconButton>  
                            </Tooltip>
                            {showFriends && <FriendNotificationsList friendNotifications={friendNotifications} userCurInfo={userCurInfo} />}
                        </div>
                    </ClickAwayListener>
                </div>           
                <div className="header__user-notifications">
                    <ClickAwayListener onClickAway={this._handleNotificationsClose}>
                        <div>
                            <Tooltip TransitionComponent={Zoom} title='Upozornění'>
                                <IconButton 
                                color="inherit"
                                onClick={this._showNotifications}
                                >
                                    <Badge 
                                    color="secondary" 
                                    badgeContent={eventsNotifications.length} 
                                    invisible={eventsNotifications.length < 1}
                                    max={notificationsLimit - 1}
                                    >
                                        <Notifications />
                                    </Badge>
                                </IconButton>
                            </Tooltip>
                            {showNotifications && <NotificationsList eventsNotifications={eventsNotifications} userCurInfo={userCurInfo} />}
                        </div>
                    </ClickAwayListener>
                </div>
            </React.Fragment>
        )
    }

    getPaginationUsers() {
        const { lastVisibleDoc, searchText } = this.state;

        const searchTextValue = searchText.toLowerCase();
        this.setState({
            dataLoaded: false
        });
        
        if (lastVisibleDoc && searchTextValue) {
            searchUserNamePagination(searchTextValue, lastVisibleDoc).then((queryData) => {
                let usersData = [];
                if (!queryData.empty) {
                    const lastDoc = queryData.docs[queryData.docs.length-1];
                    queryData.forEach(doc => {
                        usersData.push({
                            userID: doc.id,
                            ...doc.data()
                        })
                    })

                    if (this.mounted) {
                        this.setState({
                            lastVisibleDoc: lastDoc
                        })
                    }
                } else {
                    if (this.mounted) {
                        this.setState({
                            showLoadMore: false
                        })
                    }
                }

                this.props.dispatch(getSearchUsers(usersData));
                if (this.mounted) {
                    this.setState({
                        dataLoaded: true
                    })
                }
            }).catch((err) => {
                showSnackbar(this.props.enqueueSnackbar, this.props.closeSnackbar, 'Nastala chyba při hledání !', 'error', err);
            })
        } else {
            this.setState({
                dataLoaded: true
            });
        }      
    }

    _renderSearchInput() {
        const { searchResults } = this.props;
        const { searchText, showLoadMore, dataLoaded, noResults, lastVisibleDoc } = this.state;
        
        return (
            <Downshift
            onInputValueChange={(value) => {
                this.handleInputChange(value)
                this.handleSearch(value)
            }}
            onChange={selectedItem => {
                history.push(`/user/${selectedItem.userID}`);
                this.handleInputChange(selectedItem.name);
            }}
            inputValue={searchText}
            itemToString={item => (item ? item.name : '')}
            >
                {({
                    getInputProps,
                    getItemProps,
                    getMenuProps,
                    isOpen,
                    highlightedIndex
                }) => {
                    return (
                        <div>
                            <InputBase
                            {...getInputProps()}                            
                            margin="dense"
                            inputProps={{
                                autoComplete: 'off',
                                id: 'search-field'
                            }}
                            autoFocus={true}
                            onFocus={(e) => e.target.select() }
                            placeholder="Vyhledat přítele..."
                            />
                            <div {...getMenuProps()}>
                                {isOpen ? (
                                    <SearchList 
                                    value={searchText} 
                                    results={searchResults} 
                                    highlightedIndex={highlightedIndex} 
                                    itemProps={getItemProps} 
                                    usersSearchLimit={usersSearchLimit}
                                    lastVisibleDoc={lastVisibleDoc}
                                    showLoadMore={showLoadMore}
                                    dataLoaded={dataLoaded}
                                    noResults={noResults}
                                    getPaginationUsers={this.getPaginationUsers}
                                    />
                                ) : null}
                            </div>
                        </div>
                    )
                }}
            </Downshift>
        )
    }
} 

const mapStateToProps = (state) => {
    return {
        userCurInfo: state.user.userInfo,
        userName: state.user.userInfo.SF_USER_NAME,
        photoURL: state.user.userInfo.SF_USER_IMAGE_URL,
        userID: state.user.userInfo.SF_USER_UID,
        friendNotifications: state.user.friendNotifications,
        searchResults: state.user.filters.users,
        eventsNotifications: state.user.eventsNotifications,
        eventNotificationOpen: state.user.eventNotificationOpen
    }
}

export default withSnackbar(connect(mapStateToProps)(UserLogin));