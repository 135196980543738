import React from 'react';
import Loadable from 'react-loadable';
import { Router, Route, Switch } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';
import Header from './../components/Header';
import PageNotFound from '../components/error_component/PageNotFound';
import ServerError from '../components/error_component/ServerError';
import { connect } from 'react-redux';
import PrivateRouteLogout from './PrivateRouteLogout';
import PrivateRouteLogin from './PrivateRouteLogin';
import ScrollToTop from '../components/helper_components/ScrollToTop';
import Waiting from '../components/Waiting';
import Offline from '../components/Offline';
import CookieConsent from "react-cookie-consent";
import ReactGA from 'react-ga';

const AsyncHomePage = Loadable({
    loader: () => import(/* webpackChunkName: "homepage" */ './../components/HomePage'),
    loading: Waiting
});

const AsyncSportActions = Loadable({
    loader: () => import(/* webpackChunkName: "sportactions" */ '../components/sport_actions_components/SportActions'),
    loading: Waiting
});

const AsyncEventSportDetail = Loadable({
    loader: () => import(/* webpackChunkName: "eventsportdetail" */ '../components/user_component/sports/EventDetailSport'),
    loading: Waiting
});

const AsyncPrivateProfil = Loadable({
    loader: () => import(/* webpackChunkName: "publicprofil" */ '../components/user_component/PrivateProfil'),
    loading: Waiting
})

const AsyncPublicProfil = Loadable({
    loader: () => import(/* webpackChunkName: "privateprofil" */ '../components/user_component/PublicProfil'),
    loading: Waiting
})


const AsyncCreateEvent = Loadable({
    loader: () => import(/* webpackChunkName: "createevent" */ '../components/user_component/sports/CreateEvent'),
    loading: Waiting
});

const AsyncUserSports = Loadable({
    loader: () => import(/* webpackChunkName: "usersports" */ '../components/user_component/sports/UsersSports'),
    loading: Waiting
});

const AsyncEventOfficialDetail = Loadable({
    loader: () => import(/* webpackChunkName: "eventofficialdetail" */ './../components/OfficialSportsDetail'),
    loading: Waiting
});

const AsyncEventFriendDetail = Loadable({
    loader: () => import(/* webpackChunkName: "eventfrienddetail" */ '../components/user_component/sports/EventDetailFriend'),
    loading: Waiting
});

const AsyncSportCentrums = Loadable({
    loader: () => import(/* webpackChunkName: "centrums" */ '../components/centrums/SportCentrums'),
    loading: Waiting
});

const AsyncSportCentrumDetail = Loadable({
    loader: () => import(/* webpackChunkName: "centrumdetail" */ '../components/centrums/SportCentrumsDetail'),
    loading: Waiting
});

const AsyncSettings = Loadable({
    loader: () => import(/* webpackChunkName: "settings" */ '../components/user_component/Settings'),
    loading: Waiting
})

const AsyncLogin = Loadable({
    loader: () => import(/* webpackChunkName: "login" */ '../components/auth_components/Login'),
    loading: Waiting
});

const AsyncRegister = Loadable({
    loader: () => import(/* webpackChunkName: "register" */ '../components/auth_components/Register'),
    loading: Waiting
});


const AsyncNotifications = Loadable({
    loader: () => import(/* webpackChunkName: "notifications" */ '../components/user_component/notifications/Notifications'),
    loading: Waiting
})

const AsyncFriendNotifications = Loadable({
    loader: () => import(/* webpackChunkName: "friendnoti" */ '../components/user_component/notifications/FriendNotifications'),
    loading: Waiting
})

const AsyncVerificationMessage = Loadable({
    loader: () => import(/* webpackChunkName: "verification" */ '../components/auth_components/Verification'),
    loading: Waiting
})

const AsyncForgetPassword = Loadable({
    loader: () => import(/* webpackChunkName: "forgetpass" */ '../components/auth_components/ForgetPassword'),
    loading: Waiting
})

const AsyncPasswordReset = Loadable({
    loader: () => import(/* webpackChunkName: "resetpass" */ '../components/auth_components/PasswordReset'),
    loading: Waiting
})

const AsyncContact = Loadable({
    loader: () => import(/* webpackChunkName: "contact" */ './../components/Contact'),
    loading: Waiting
})

const AsyncSportclubDetail = Loadable({
    loader: () => import(/* webpackChunkName: "sportclubdetail" */ './../components/sportclubs/SportclubDetail'),
    loading: Waiting
})

const AsyncSportclubs = Loadable({
    loader: () => import(/* webpackChunkName: "sportclubs" */ './../components/sportclubs/Sportclubs'),
    loading: Waiting
})

const AsyncPrivacyPolicy = Loadable({
    loader: () => import(/* webpackChunkName: "privacypolicy" */ '../components/PrivacyPolicy'),
    loading: Waiting
})


export const history = createHistory();

history.listen(location => {
    ReactGA.set({ page: location.pathname }); 
    ReactGA.pageview(location.pathname); 
});

class AppRouter extends React.Component{
    constructor(props){
        super(props);

        this._renderOnline = this._renderOnline.bind(this);
        this._renderOffline = this._renderOffline.bind(this);
    }   

    render() {     
        const { online } = this.props;
        return (
            <Router history={history} >
                <ScrollToTop>
                    <Header />
                    {online ? this._renderOnline() : this._renderOffline()}      
                </ScrollToTop>
            </Router>
        )
    }

    _renderOnline() {
        return (
            <React.Fragment>
                <Switch>
                    <Route exact={true} path="/" component={AsyncHomePage} />                    
                    <PrivateRouteLogin exact={true} path="/profil/settings" component={AsyncSettings} />
                    <PrivateRouteLogin exact={true} path="/profil/create-event" component={AsyncCreateEvent} />
                    <PrivateRouteLogin exact={true} path="/profil/notifications" component={AsyncNotifications} />
                    <PrivateRouteLogin exact={true} path='/profil/friends-notifications' component={AsyncFriendNotifications} />
                    <PrivateRouteLogin path="/profil" component={AsyncPrivateProfil} />
                    <PrivateRouteLogin exact={true} path="/user/:id" component={AsyncPublicProfil} />  
                    <Route path="/users-sports" component={AsyncUserSports} />
                    <Route exact={true} path='/centrums' component={AsyncSportCentrums} />
                    <Route path='/centrums/detail/:id' component={AsyncSportCentrumDetail} />                    
                    <PrivateRouteLogin exact={true} path="/event/detail/:id" component={AsyncEventFriendDetail} />               
                    <Route exact={true} path="/sport/event/detail/:id" component={AsyncEventSportDetail} />                 
                    <Route exact={true} path="/sports" component={AsyncSportActions} />
                    <Route path="/sports/detail/:id" component={AsyncEventOfficialDetail} />
                    <Route exact={true} path="/clubs" component={AsyncSportclubs} />
                    <Route exact={true} path="/clubs/detail/:id" component={AsyncSportclubDetail} />
                    <PrivateRouteLogout path="/login" component={AsyncLogin} />
                    <PrivateRouteLogout path="/register" component={AsyncRegister} />   
                    <PrivateRouteLogout exact={true} path="/email/verification/message" component={AsyncVerificationMessage} /> 
                    <PrivateRouteLogout exact={true} path="/password-reset" component={AsyncForgetPassword} />
                    <PrivateRouteLogout exact={true} path="/password-reset/message" component={AsyncPasswordReset} />
                    <Route path='/contact' component={AsyncContact} />
                    <Route exact={true} path="/privacy-policy" component={AsyncPrivacyPolicy} />
                    <Route exact={true} path="/500-internal-error" component={ServerError} />              
                    <Route component={PageNotFound} />
                </Switch>   
                <CookieConsent
                    buttonText="Souhlasím"
                    style={{ zIndex: 1500 }}
                >
                    Tento web používá k poskytování služeb a analýze návštěvnosti soubory cookie. Používáním tohoto webu s tím souhlasíte.
                </CookieConsent>
            </React.Fragment>
        )
    }

    _renderOffline() {
        return (
            <Offline />
        )
    }
}

const mapStateToProps = (state) => ({
    online: state.user.online
})

export default connect(mapStateToProps)(AppRouter);