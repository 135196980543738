import React from 'react';

const LoadingPage = () => (
    <div className="loading-wrapper">      

        <div className="loading">
            <img src="/images/logo/sf_logo.svg" alt="logo" className="loading__logo" />
            <h3>SportyFind</h3>
        </div>
       
    </div>
);

export default LoadingPage;