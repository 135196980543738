import moment from 'moment';

// pre obnovenie hesla alebo validacia email adresy sa user vrati na tuto stranku
export const actionCodeSettings = {
    url: __API__
}

export const actionCodeSettingsLogin = {
    url: `${__API__}/login`
}

export const apiUrl = `${__API__}/api/v1`;

export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const webRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

export const providersID = {
    google: 'google.com',
    facebook: 'facebook.com'
}

export const CANCELED = 'Zrušeno';
export const POSPONE = 'Odloženo';

// ZACIATOK DNESNEHO DNA OD 0:00:00
export const TODAY = moment().set({'hour': 0, 'minute': 0, 'second': 0}).valueOf()

export const firebaseStorageUrl = 'https://firebasestorage.googleapis.com/';
export const firebaseStorageUrlPlaceholder = 'user-placeholder-final.png';

export const sfMailAddress = 'kontakt@sportyfind.cz';
export const sfCooperationAddress = 'spoluprace@sportyfind.cz';

export const firebaseEventsLimit = 8;

export const participantsLimit = 8;

export const placeholdersValue = 5;

export const notificationsLimit = 3;

export const usersSearchLimit = 5;

export const eventMessagesLimit = 8;

export const apiLimit = 20;

export const mailToSportClub = `mailto:${sfMailAddress}?subject=Pridani sportovního klubu&body=Dobrý den,%0D%0A%0D%0Achtěl bych přidat sportovní klub.
%0D%0A%0D%0ANázev klubu:%0D%0ATyp Sportu:%0D%0AAdresa:%0D%0AZeměpisná šírka:%0D%0AZeměpisná délka:%0D%0A
Soutěž:%0D%0AWebová stránka:%0D%0A%0D%0A!!! Do přílohy přidat Exccel s rozpisem domácích zápasů !!!%0D%0A
Sloupce excel tabulky: Domaci, Hoste, Čas
%0D%0A%0D%0ADekuji%0D%0A%0D%0AS pozdravem`;

export const mailToCentrum = `mailto:${sfMailAddress}?subject=Pridani strediska&body=Dobrý den,%0D%0A%0D%0Achtěl bych přidat 
středisko.%0D%0A%0D%0ANázev:%0D%0AAdresa:%0D%0AZeměpisná šírka:%0D%0AZeměpisná délka:%0D%0ATyp strediska:%0D%0APopis:%0D%0AWebová stránka:%0D%0A%0D%0A
Děkuji%0D%0A%0D%0AS pozdravem`;

export const sportsUserAvailible = ['aerobik', 'americký fotbal', 'badminton', 'basebal', 'basketbal', 'beachvolejbal', 'box', 'bowling', 'cyklistika', 'deskové hry', 'esports',
    'florbal', 'fotbal', 'frisbee', 'futsal', 'hokej', 'golf', 'házená', 'hokejbal', 'horolezectví', 'jóga', 'kanoistika', 'karate', 'kriket', 'kulečník', 'lezení', 'lukostřelba',
    'minigolf', 'nohejbal', 'orientačný běh', 'paintbal', 'pilates', 'posilovna', 'ragby', 'squash', 'stolní tenis', 'šachy', 'šerm', 'šipky', 'tenis', 'vodní pólo', 'volejbal', 'jiné'];

export const sportsUserAvailibleApi = {
    'fotbal': 'fotbal',
    'hokej': 'hokej',
    'basketbal': 'basketbal',
    'futsal': 'futsal',
    'házená': 'hazena',
    'hokejbal': 'hokejbal',
    'volejbal': 'volejbal',
    'tenis': 'tenis',
    'aerobik': 'aerobik',
    'americký fotbal': 'americky-fotbal',
    'badminton': 'badminton',
    'basebal': 'basebal',
    'box': 'box',
    'cyklistika': 'cyklistika',
    'golf': 'golf',
    'horolezectví': 'horolezectvi',
    'esports': 'esports',
    'beachvolejbal': 'beachvolejbal',
    'jóga': 'joga',
    'kanoistika': 'kanoistika',
    'karate': 'karate',
    'kulečník': 'kulecnik',
    'lezení': 'lezeni',
    'paintbal': 'paintbal',
    'posilovna': 'posilovna',
    'kriket': 'kriket',
    'jiné': 'jine',
    'bowling': 'bowling',
    'lukostřelba': 'lukostrelba',
    'minigolf': 'minigolf',
    'nohejbal': 'nohejbal',
    'orientačný běh': 'orientacny-beh',
    'pilates': 'pilates',
    'šipky': 'sipky',
    'šerm': 'serm',
    'ragby': 'ragby',
    'šachy': 'sachy',
    'squash': 'squash',
    'stolní tenis': 'stolni-tenis',
    'vodní pólo': 'vodni-polo',
    'florbal': 'florbal',
    'frisbee': 'frisbee',
    'deskové hry': 'deskove-hry'
}

export const sportsAvailible = ['fotbal', 'hokej', 'basketbal', 'házená', 'volejbal'];
export const sportsAvailibleApi = {
    'fotbal': 'fotbal',
    'hokej': 'hokej',
    'basketbal': 'basketbal',
    'házená': 'hazena',
    'volejbal': 'volejbal'
}

export const centrumsAvailible = ['bowling', 'fitness', 'laser-game', 'lezení', 'lyžování', 'minigolf', 'paintbal', 'plavání', 'tenis'];
export const centrumsAvailibleApi = {
    'fitness': 'fitness',
    'plavání': 'plavani',
    'bowling': 'bowling',
    'tenis': 'tenis',
    'paintbal': 'paintbal',
    'lezení': 'lezeni',
    'laser-game': 'laser-game',
    'lyžování': 'lyzovani',
    'minigolf': 'minigolf'
}

export const distanceAvailible = [
    {
        name: 'Neomezeně',
        value: 0
    },
    {
        name: 'do 5 km',
        value: 5
    }, 
    {
        name: 'do 10 km',
        value: 10
    },
    {
        name: 'do 20 km',
        value: 20
    },
    {
        name: 'do 50 km',
        value: 50
    }    
]