import React from 'react';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';

class PageNotFound extends React.PureComponent{
    constructor(props) {
        super(props);
    }

    render() {
      return (
        <React.Fragment>
          <Helmet>
              <title>Stránka nenalezena - 404.</title>
              <meta name='description' />
              <meta name="robots" content="noindex, nofollow" />
          </Helmet>
          <div className="not-found">
            <div className="not-found__wrapper">
              <div className="not-found__ups">Oops !</div>
              {this._renderEventInfo()}
              <Button
              className="not-found__btn"
              variant="contained"
              color="secondary"
              component={Link}
              to="/"
              >
                Pokračovat na hlavní stránku
              </Button>
            </div>
          </div>
          <Footer />
        </React.Fragment>
      )
    }

    _renderEventInfo() {
      const { location } = this.props;
      const { state = {} } = location;
      const { from = ''} = state;

      if (from === 'event') {
        return (
            <div className="not-found__info"><span>404</span> - Událost nenalezena</div>
        )
      } else if (from === 'user') {
        return (
          <div className="not-found__info"><span>404</span> - Uživatel nenalezen</div>
        )
      } else if (from === 'centrum') {
        return (
          <div className="not-found__info"><span>404</span> - Sportovní centrum nenalezeno</div>
        )
      } else if (from === 'club') {
        return (
          <div className="not-found__info"><span>404</span> - Sportovní klub nenalezen</div>
        )
      } else {
        return (
          <div className="not-found__info"><span>404</span> - Stránka nenalezena</div>
        )
      }
    }
} 

export default PageNotFound;