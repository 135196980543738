import moment from 'moment';

export const formatNumber = (number) => {
    let phone = String(number);

    if (phone.length === 9) {
        phone = phone.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3');
    } else if (phone.length === 13) {
        phone = phone.replace(/(\S{4})(\d{3})(\d{3})(\d{3})/, '$1 $2 $3 $4');
    }

    return phone;
}

export const formatDateText = (date) => {
    let timeValue = '';

    const now = moment();
    const dateMoment = moment(date);

    const seconds = now.diff(dateMoment, 'seconds');
    const minutes = now.diff(dateMoment, 'minutes');
    const hours = now.diff(dateMoment, 'hours');
    const days = now.diff(dateMoment, 'days');
    const months = now.diff(dateMoment, 'months');
    const years = now.diff(dateMoment, 'years');

    if (years > 0) {
        timeValue = `${years} r`;
    } else if (months > 0) {
        timeValue = `${months} mes`;
    } else if (days > 0) {
        timeValue = `${days} d`
    } else if (hours > 0) {
        timeValue = `${hours} hod`;
    } else if (minutes > 0) {
        timeValue = `${minutes} min`;
    } else {
        timeValue = `${seconds} s`;
    }

    return timeValue;
}