import { FETCH_CLUBS_BEGGINS, FETCH_CLUBS_SUCCESS, FETCH_CLUBS_FAILED } from '../actions/clubsActions';

const initialState = {
    clubs: {},
    dataListLoaded: false,
    error: null
}

export const clubsReducer = ( state = initialState, action ) => {
    switch(action.type) {
        case FETCH_CLUBS_BEGGINS:
            return {
                ...state,
                dataListLoaded: false,
                clubs: {}
            };

        case FETCH_CLUBS_SUCCESS:
            return {
                ...state,
                dataListLoaded: true,
                clubs: {...action.payload.clubs} || {}
            }

        case FETCH_CLUBS_FAILED:
            return {
                ...state,
                dataListLoaded: true,
                error: action.payload.error,
                clubs: {}
            }

        default:
            return state
    }
}