import { 
    SET_CLUBS_SPORT_FILTER,
    SET_CLUBS_FULLTEXT_FILTER,
    SET_CLUBS_LEAGUE_FILTER,
    SET_CLUBS_LEAGUES_FILTER
} from '../actions/clubsFilterActions';

const clubsFilterInitialState = {
    sport: '',
    text: '',
    league: '',
    leagues: []
}

export const clubsFilterReducer = (state = clubsFilterInitialState, action) => {
    switch(action.type) {
        case SET_CLUBS_SPORT_FILTER:
            return {
                ...state,
                sport: action.sport
            }

        case SET_CLUBS_FULLTEXT_FILTER:
            return {
                ...state,
                text: action.text
            }

        case SET_CLUBS_LEAGUE_FILTER:
            return {
                ...state,
                league: action.league
            }

        case SET_CLUBS_LEAGUES_FILTER:
            return {
                ...state,
                leagues: action.leagues
            }

        default:
            return state;
    }
}