import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import { startAcceptFriend, 
        startRejectFriend,
        startDeleteNotification } from '../../../actions/usersActions';
import { withSnackbar } from 'notistack';
import { showSnackbar } from '../../../helper_func/SnackBarcCallback';
import { notificationsLimit } from '../../../constants/constants';
import { formatDateText } from '../../../helper_func/Format';

class FriendNotificationsList extends React.PureComponent {
    constructor(props) {
        super(props);  
    }
    
    render() {
    const { friendNotifications, userCurInfo } = this.props;

      return (
        <div 
        className='friend-notification-list'
        >
            <List>
                { friendNotifications.length > 0 ? (
                    <React.Fragment>
                        {friendNotifications.slice(0, notificationsLimit).map((notification) => {
                            const time = notification.date ? formatDateText(notification.date) : '';

                            return (
                                <ListItem 
                                key={notification.notificationID} 
                                dense
                                className="list-item"
                                >
                                <Link to={`/user/${notification.notificationID}`} >                      
                                    <Avatar src={notification.photo} />                                           
                                </Link>
                                <ListItemText
                                    primary={<Link className="not-name" to={`/user/${notification.notificationID}`} >{notification.name}</Link>}
                                    secondary={ notification.about === 'CONFIRMATION_FRIEND_REQUEST' ? 'Přijal vaši žádost' : 'vám posílá žádost o přátelství'}
                                />                    
                                    {notification.about === 'FRIEND_REQUEST' ? (
                                            <div>
                                                <div className="not-time">{time}</div>
                                                <Button 
                                                color="primary" 
                                                size="small" 
                                                onClick={() => {
                                                    startAcceptFriend(notification.notificationID, {
                                                        name: userCurInfo.SF_USER_NAME,
                                                        photo: userCurInfo.SF_USER_IMAGE_URL,
                                                        userID: userCurInfo.SF_USER_UID
                                                    }, {
                                                        name: notification.name,
                                                        photo: notification.photo,
                                                        userID: notification.notificationID
                                                    }).catch((err) => {
                                                        showSnackbar(this.props.enqueueSnackbar, this.props.closeSnackbar, 'Nastala chyba při potvrzení uživatele nebo uživatel neexistuje !', 'error', err);
                                                    })
                                                }}
                                                >
                                                    Přijat
                                                </Button>
                                                <Button 
                                                className="reject-button" 
                                                variant="text" 
                                                size="small"
                                                onClick={() => {
                                                    startRejectFriend(notification.notificationID).catch((err) => {
                                                        showSnackbar(this.props.enqueueSnackbar, this.props.closeSnackbar, 'Nastala chyba při odstranení uživatele !', 'error', err);
                                                    })
                                                }}>
                                                    Odstranit
                                                </Button>                                                
                                            </div>
                                        ) : (
                                            <div>
                                                <div className="not-time">{time}</div>
                                                <Button 
                                                    className="reject-button" 
                                                    variant="text" 
                                                    size="small"
                                                    onClick={() => {
                                                        startDeleteNotification('friends', notification.notificationID).catch((err) => {
                                                            showSnackbar(this.props.enqueueSnackbar, this.props.closeSnackbar, 'Nastala chyba při odstránení notifikace !', 'error', err);
                                                        })
                                                    }}>
                                                        Odstranit notifikaci
                                                </Button>
                                            </div>
                                        )                                            
                                    }                                           
                                </ListItem>
                            )
                        })}
                        <ListItem
                        className="list-item"
                        >
                            <Link className='show-all' to='/profil/friends-notifications'>Zobrazit všechny</Link>
                        </ListItem>
                    </React.Fragment>
                ) : (
                    <ListItem>
                        Nemáte žádné žádosti o přátelství
                    </ListItem>
                )}
            </List>
        </div>
      )
    }    
}

export default withSnackbar(FriendNotificationsList);