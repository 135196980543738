import { FETCH_OFFICIAL_SPORTS_BEGGINS, FETCH_OFFICIAL_SPORTS_FAILED, FETCH_OFFICIAL_SPORTS_SUCCESS,
        FETCH_OFFICIAL_DETAIL_FAILED, FETCH_OFFICIAL_DETAIL_SUCCESS, FETCH_OFFICIAL_DETAIL_BEGGINS,
        FETCH_OFFICIAL_DETAIL_RESET } from '../actions/officialSportsActions';


const initialState = {
    sports: {},
    sportDetail: [],
    dataListLoaded: false,
    dataDetailLoaded: false,
    error: null
};

export const sportsOfficialReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_OFFICIAL_SPORTS_BEGGINS:
            return {
                ...state,
                dataListLoaded: false,
                error: null,
                sports: {}
            };
            
        case FETCH_OFFICIAL_DETAIL_BEGGINS:
            return {
                ...state,
                dataDetailLoaded: false,
                error: null
            }; 
        
        case FETCH_OFFICIAL_SPORTS_SUCCESS:
            return {
                ...state,
                dataListLoaded: true,
                sports: {...action.payload.sports} || {}
            };

        case FETCH_OFFICIAL_DETAIL_SUCCESS:
            return {
                ...state,
                dataDetailLoaded: true,
                sportDetail: action.payload.sport || []
            }

        case FETCH_OFFICIAL_SPORTS_FAILED:
            return {
                ...state,
                dataListLoaded: true,
                error: action.payload.error,
                sports: {}
            };

        case FETCH_OFFICIAL_DETAIL_FAILED:
            return {
                ...state,
                dataDetailLoaded: true,
                error: action.payload.error,
                sportDetail: []
            }

        case FETCH_OFFICIAL_DETAIL_RESET:
            return {
                ...state,
                dataDetailLoaded: false,
                error: null,
                sportDetail: []
            }

        default:
            return state
    };
}


