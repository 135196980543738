import database, { firebase, functions, googleAuthProvider, facebookAuthProvider } from '../firebase/firebase';
import { history } from '../routers/AppRouter';
import { providersID, actionCodeSettings, actionCodeSettingsLogin } from '../constants/constants';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const CHANGE_PHOTO = 'CHANGE_PHOTO';
export const CHANGE_NAME = 'CHANGE_NAME';
export const ADD_PHONE = 'ADD_PHONE';
export const ADD_MESSENGER = 'ADD_MESSENGER';
export const ADD_PROVIDERS = 'ADD_PROVIDERS';

const BATCH_MAX_SIZE = 499;

/**
 * Prihlasi uzivatela cez google - redirect
 */
export const startLoginGoogleRedirect = () => {  
    return firebase.auth().signInWithRedirect(googleAuthProvider);
};

/**
 * Prihlasi uzivatela cez google - popup
 */
export const startLoginGooglePopup = () => {
    return firebase.auth().signInWithPopup(googleAuthProvider);
}

/**
 * Prihlasi uzivatela cez Facebook, ak nie je overeny email, 
 * tak posle overovaci email na jeho adresu - redirect
 */
export const startLoginFacebookRedirect = () => { 
    return firebase.auth().signInWithRedirect(facebookAuthProvider).then((result) => {
        if(result.user && !result.user.emailVerified){  
            resentVerificationEmail().catch((err) => {
                //TODO posli do google analytics
            })
        }
    })    
};

/**
 * Prihlasi uzivatela cez Facebook, ak nie je overeny mail,
 * tak posle overovaci email na jeho adresu - popup
 */
export const startLoginFacebookPopup = () => { 
    return firebase.auth().signInWithPopup(facebookAuthProvider).then((result) => {
        if(result.user && !result.user.emailVerified){  
            resentVerificationEmail().catch((err) => {
                //TODO posli do google analytics
            })
        }
    })    
};

/**
 * Prihlasi uzivatela cez email a heslo
 * @param {String} email Email uzivatela
 * @param {String} password Heslo uzivatela
 */
export const startLoginEmailPassword = (email, password) => {    
    return firebase.auth().signInWithEmailAndPassword(email, password);    
};

/**
 * Vytvori noveho uzivatela a ulozi do DB
 * @param {Object} userData Data o uzivatelovi
 */
export const createUserEmailPassword = (userData) => {      
    return firebase.auth().createUserWithEmailAndPassword(userData.email, userData.password);          
};

/**
 * Vytvori a ulozi novy zazname do DB - uzivatel
 * TODO
 * @param {String} userId Id uzivatela
 * @param {Object} userDataPublic Data o uzivatelovi - verejne
 * @param {Object} userDataPrivate Data o uzivatelovi - privatne
 */
export const startCreateUserInDB = ( userId, userDataPublic, userDataPrivate ) => {
    const batch = database.batch();
    const publicRef = database.collection('users').doc(userId);
    batch.set(publicRef, userDataPublic);

    const privateRef = database.collection('users').doc(userId).collection('private').doc(userId);
    batch.set(privateRef, userDataPrivate);

    return batch.commit();
}

/**
 * Odhlasi uzivatela
 */
export const startLogout = () => {
    return firebase.auth().signOut();
};

/**
 * 
 * @param {String} uid user UID
 * @param {String} name user Name
 * @param {String} email user email
 * @param {String} photoURL odkaz na photo
 * @param {Array} providers users sign in providers
 * @param {Number} phone phone number
 * @param {String} messenger messenger
 * @param {Number} whatsapp whatsapp
 */
export const dispatchLogin = (uid, name, email, photoURL, providers = [], phone = '', messenger = '') => ({
    type: LOGIN,
    uid,
    name,
    email,
    photoURL,
    providers,
    phone,
    messenger
});

export const dispatchLogout = () => ({
    type: LOGOUT
});

/**
 * Odosle znova overovaci email
 */
export const resentVerificationEmail = () => {
    const user = firebase.auth().currentUser;

    if (user !== null && user.emailVerified) {
        return user.sendEmailVerification(actionCodeSettings).then(() => {
              history.push('/email/verification/message', {
                email: user.email,
                error: false
              });
        }).catch((error) => {
            history.push('/email/verification/message', {
                email: user.email,
                error: true
              });
        })
    } else {
        return Promise.reject('Uzivatel neexistuje !');
    }
}

/**
 * Obnovi uzivatelovi heslo
 * @param {String} email Novy email
 */
export const resetUserPassword = ( email ) => {
    return firebase.auth().sendPasswordResetEmail(email, actionCodeSettingsLogin);
}

/**
 * 
 * USERS LOGIN AND UPDATE METHOD = MANAGE USER --------------------------------
 * 
 */ 

 /**
  * Aktualizuje uzivatela
  * TODO
  * @param {Object} updates Data, ktore chceme aktualizovat
  */
export const updateUserProfil = ( updates ) => {
    const user = firebase.auth().currentUser;

    if (user !== null && user.emailVerified) {
        return database.collection('users').doc(user.uid).update(updates)
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }
    
}

/**
 * Dispatch action pre zmenu fotky
 * @param {String} photoURL Url fotky
 */
export const changePhoto = ( photoURL ) => ({
    type: CHANGE_PHOTO,
    photoURL
});

/**
 * Ulozi fotku do storage
 * @param {String} photo Foto, ktora sa ma ulozit
 * @param {String} photoName Nazov fotky
 */
export const saveUserPhoto = (photo, photoName, userId) => {   
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        const storageRef = firebase.storage().ref('users').child(currentUser.uid).child('profilePhoto').child(photoName);
        const task = storageRef.putString(photo, 'data_url', {customMetadata: {"owner": userId}});
        return task; 
    } else {
        return Promise.reject('Uzivatel neexistuje !');
    }   
}

/**
 * Dispatch action pre zmenu mena
 * @param {String} name Nove meno
 */
export const changeName = ( name ) => ({
    type: CHANGE_NAME,
    name
});

/**
 * Vytvori novy email
 * @param {String} email Novy email
 */
export const updateUserEmailAddress = ( email ) => {
    const currentUser = firebase.auth().currentUser;
    
    if (currentUser !== null && currentUser.emailVerified) {
        return currentUser.updateEmail(email).then(() => {
            currentUser.sendEmailVerification(actionCodeSettings)
        })
    } else {
        return Promise.reject('Uzivatel neexistuje !');
    }
}

/**
 * Vytvori nove heslo
 * @param {String} password Nove heslo
 */
export const updateUserPassword = ( password ) => {
    const currentUser = firebase.auth().currentUser;
    
    if (currentUser !== null && currentUser.emailVerified) {
        return currentUser.updatePassword(password)
    } else {
        return Promise.reject('Uzivatel neexistuje !');
    }
}

/**
 * Skontroluje ci je uzivatel prihlaseny pomocou emailu a hesla
 */
export const isUserSignInWithEmailAndPassword = () => {
    const currentUser = firebase.auth().currentUser;
    let isUsedEmailAndPass = false;

    if (currentUser !== null && currentUser.emailVerified) {
        const providers = currentUser.providerData;

        isUsedEmailAndPass = !!providers.find((provider) => {
            return provider.providerId === 'password'
        });
        
    }

    return isUsedEmailAndPass;
}

/**
 * Reautentifikuje uzivatela
 * NOT USED
 */
export const reAuthenticateUser = () => {
    const currentUser = firebase.auth().currentUser;
    let provider = '';

    if (currentUser !== null && currentUser.emailVerified) {
        provider = currentUser.providerData[0].providerId;
        if (provider === providersID.google) {
            return firebase.auth().signInWithRedirect(googleAuthProvider)
        } else if (provider === providersID.facebook) {
            return firebase.auth().signInWithRedirect(facebookAuthProvider)
        } else {
            return Promise.resolve('email');
        }
    } else {
        return Promise.reject('Uzivatel neexistuje !');
    }
}

/**
 * Dispatch action pre telefon
 * @param {String} phone Telefonne cislo
 */
export const addPhone = (phone) => ({
    type: ADD_PHONE,
    phone
});

/**
 * Aktualizuje alebo prida telefonne cislo
 * @param {String} phoneNumber Nove telefonne cislo
 */
export const startAddPhoneNumber = (phoneNumber) => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        return database.collection('users').doc(currentUser.uid).collection('private').doc(currentUser.uid).update({
            phone: phoneNumber
        })
    } else {
        return Promise.reject('Uzivatel neexistuje !');
    }
}

/**
 * Odstrani telefonne cislo
 */
export const startRemovePhoneNumber = () => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        return database.collection('users').doc(currentUser.uid).collection('private').doc(currentUser.uid).update({
            phone: ''
        })
    } else {
        return Promise.reject('Uzivatel neexistuje !');
    }
}

/**
 * Dispatch action pre nastavenie messenger mena
 * @param {String} messenger Messenger meno
 */
export const addMessenger = (messenger) => ({
    type: ADD_MESSENGER,
    messenger
});

/**
 * Prida ako kontakt messenger
 * @param {String} messenger Prida messenger meno
 */
export const startAddMessenger = (messenger) => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        return database.collection('users').doc(currentUser.uid).collection('private').doc(currentUser.uid).update({
            messenger
        })
    } else {
        return Promise.reject('Uzivatel neexistuje !');
    }
}

/**
 * Odstrani messenger meno z profilu
 */
export const startRemoveMessenger = () => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        return database.collection('users').doc(currentUser.uid).collection('private').doc(currentUser.uid).update({
            messenger: ''
        })
    } else {
        return Promise.reject('Uzivatel neexistuje !');
    }
}

/**
 * LINK PROVIDERS
 */

/**
 * Dispatch action pre nastavenie providerov
 * @param {Array} providers Pole providerov
 */
export const addProviders = (providers = []) => ({
    type: ADD_PROVIDERS,
    providers
});

/**
 * Prelinkuje ucet s uctom od Google - popup
 */
export const startLinkGoogleAcountPopup = () => {
    const user = firebase.auth().currentUser;

    if (user !== null && user.emailVerified) {
        return user.linkWithPopup(googleAuthProvider);
    } else {
        return Promise.reject('Uzivatel neexistuje !'); 
    }
}

/**
 * Prelinkuje ucet s uctom od Google - redirect
 */
export const startLinkGoogleAcountRedirect = () => {
    const user = firebase.auth().currentUser;

    if (user !== null && user.emailVerified) {
        return user.linkWithRedirect(googleAuthProvider);
    } else {
        return Promise.reject('Uzivatel neexistuje !'); 
    }
}

/**
 * Odlinkuje ucet s uctom od Google
 */
export const startUnlinkGoogleAcount = () => {
    const user = firebase.auth().currentUser;

    if (user !== null && user.emailVerified) {
        return user.unlink(providersID.google);
    } else {
        return Promise.reject('Uzivatel neexistuje !');
    }
}

/**
 * Prelinkuje ucet s uctom od Facebooku - popup
 */
export const startLinkFacebookAcountPopup = () => {
    const user = firebase.auth().currentUser;

    if (user !== null && user.emailVerified) {
        return user.linkWithPopup(facebookAuthProvider);
    } else {
        return Promise.reject('Uzivatel neexistuje !');
    }
}

/**
 * Prelinkuje ucet s uctom od Facebooku - redirect
 */
export const startLinkFacebookAcountRedirect = () => {
    const user = firebase.auth().currentUser;

    if (user !== null && user.emailVerified) {
        return user.linkWithRedirect(facebookAuthProvider);
    } else {
        return Promise.reject('Uzivatel neexistuje !');
    }
}

/**
 * Odlinkuje ucet s uctom od Facebooku
 */
export const startUnlinkFacebookAcount = () => {
    const user = firebase.auth().currentUser;

    if (user !== null && user.emailVerified) {
        return user.unlink(providersID.facebook);
    } else {
        return Promise.reject('Uzivatel neexistuje !');
    }
}

/**
 * Odstrani uzivatela z DB
 */
export const removeUserFromDB = functions.httpsCallable('deleteUser');

/**
 * Upravy fotku
 */
export const updateUserProfilePhoto = functions.httpsCallable('updatePhoto');

/**
 * Zmeni meno
 */
export const updatUserName = functions.httpsCallable('updateName');
