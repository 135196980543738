export const CREATE_EVENT_ADDRESS = 'CREATE_EVENT_ADDRESS'; 
export const SET_MY_EVENTS = 'SET_MY_EVENTS';
export const SET_MY_EVENTS_TYPE = 'SET_MY_EVENTS_TYPE';
export const SET_MY_SPORT_EVENTS = 'SET_MY_SPORT_EVENTS';
export const SET_MY_JOINED_EVENTS = 'SET_MY_JOINED_EVENTS';
export const EDIT_MY_EVENT = 'EDIT_MY_EVENT';
export const GET_EVENTS_NOTIFICATIONS = 'GET_EVENTS_NOTIFICATIONS';
export const GET_FRIEND_NOTIFICATIONS = 'GET_FRIEND_NOTIFICATIONS';
export const GET_FRIENDS = 'GET_FRIENDS';
export const SET_INVITE_FRIEND = 'SET_INVITE_FRIEND';
export const REMOVE_INVITE_FRIEND = 'REMOVE_INVITE_FRIEND';
export const RESET_INVITE_FRIEND = 'RESET_INVITE_FRIEND';
export const RESET_MY_FRIENDS_CASHED = 'RESET_MY_FRIENDS_CASHED';
export const SEARCH_USERS = 'SEARCH_USERS';
export const RESET_SEARCH_USERS = 'RESET_SEARCH_USERS';
export const RESET_MY_EVENTS_CASHED = 'RESET_MY_EVENTS_CASHED';
export const RESET_MY_SPORT_EVENTS_CASHED = 'RESET_MY_SPORT_EVENTS_CASHED';
export const RESET_MY_JOINED_EVENTS_CASHED = 'RESET_MY_JOINED_EVENTS_CASHED';
export const SET_EVENT_PARTICIPANTS = 'SET_EVENT_PARTICIPANTS';
export const RESET_EVENT_PARTICIPANTS = 'RESET_EVENT_PARTICIPANTS';
export const OPEN_EVENT_NOTIFICATION = 'OPEN_EVENT_NOTIFICATION';
export const CASHED_EVENTS_NOTIFICATIONS = 'CASHED_EVENTS_NOTIFICATIONS';
export const CASHED_FRIEND_EVENTS_NOTIFICATIONS = 'CASHED_FRIEND_EVENTS_NOTIFICATIONS';
export const DELETE_EVENT_NOTIFICATION = 'DELETE_EVENT_NOTIFICATION';
export const DELETE_FRIEND_NOTIFICATION = 'DELETE_FRIEND_NOTIFICATION';
export const USER_ONLINE = 'USER_ONLINE';
import database, {firebase, functions} from '../firebase/firebase';
import { participantsLimit, firebaseEventsLimit, notificationsLimit, usersSearchLimit, eventMessagesLimit } from '../constants/constants';
import moment from 'moment';

const FRIEND_EVENTS_COL = 'friend_events';

// GET USER INFO

/**
 * Vrati data o uzivatelovi
 * @param {String} userId Id uzivatela
 */
export const startGetMyUser = ( userId ) => {
    if (isUserLoggedIn()) {
        return database.collection('users').doc(userId).get();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }    
};

/**
 * Overi ci je uzivatel prihlaseny
 */
export const isUserLoggedIn = () => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        return true;
    } else {
        return false;
    }
}

/**
 * Vrati privatne informacie o uzivatelovi 
 */
export const startGetMyUserPrivateInfo = () => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        return database.collection('users').doc(currentUser.uid).collection('private').doc(currentUser.uid).get();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }    
}

/**
 * EVENTS
 * 
 */

 /**
  * Dispatch action, ktora nastavi adresu pre vytvorenie udalosti
  * @param {Object} address adresa
  */
export const createEventAddress = (address = 
    { 
        name: "", 
        longitude: '14.47', 
        latitude: '50.07', 
        zoom: 4,
        addressInfo: {}
    }) => ({
    type: CREATE_EVENT_ADDRESS,
    address
});

/**
 * Vytvori novu udalost pre priatelov - mozu sa pridat
 * @param {Object} eventsData data, ktore su potrebne k vytvoreniu udalosti
 */
export const startAddFriendEvent = (eventsData = {
    owner: { userId: '', name: '', photo: ''}, description: '', address: { name: "", longitude: 0, latitude: 0, zoom: 4 },
    date: 0, type: '', info: '', createdAt: 0, access: 'public', access_uid: ''
}, access) => {    
    return database.collection('events').doc(access).collection(FRIEND_EVENTS_COL).add(eventsData);
}

export const deleteFriendEvent = functions.httpsCallable('deleteFriendEvent');

/**
 * Vytvori novu verejnu udalost - neda sa tam pridat
 * @param {Object} eventsData data, ktore su potrebne k vytvoreniu udalosti
 */
export const startAddSportActionEvent = (eventsData = 
    {owner: { userId: '', name: '', photo: ''}, description: '', address: { name: "", longitude: 0, latitude: 0, zoom: 4 },
    date: 0, type: '', info: '', createdAt: 0, web: ''}) => {
        return database.collection('events').doc('actions').collection('sport_actions').add(eventsData);
}

/**
 * Aktualizuje verejnu udalost
 * @param {String} eventId Id udalosti
 * @param {Object} updates data ktore chceme updatnut
 */
export const startUpdateSportActionEvent = (eventId, updates) => {
    if (isUserLoggedIn()) {
        return database.collection('events').doc('actions').collection('sport_actions').doc(eventId).update(updates);
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }
}

/**
 * Odstrani verejnu akciu
 * @param {String} eventId Id udalosti
 */
export const startRemoveSportActionEvent = (eventId) => {
    if (isUserLoggedIn()) {
        return database.collection('events').doc('actions').collection('sport_actions').doc(eventId).delete();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }
}

/**
 * Dispatch action pre nacitanie mojich udalosti
 * @param {Array} eventsData pole dat o udalosti
 */
export const setMyEvents = ( eventsData ) => ({
    type: SET_MY_EVENTS,
    eventsData
});

/**
 * Dispatch action pre resetovanie nacashovanych udalosti
 * @param {Boolean} cashed priznak, ci chceme aby sme brali udalosti z cashe alebo nacitali nove
 */
export const resetMyEventsCashed = ( cashed = false ) => ({
    type: RESET_MY_EVENTS_CASHED,
    cashed
});

/**
 * Vrati prvych {limit} udalosti - mojich friend
 * @param {String} userID Id uzivatela - prihlaseneho alebo mojho priatela
 * @param {Number} limit limit kolko udalosti chceme nacitat
 */
export const startSetMyEventsFirst = (userID, limit = firebaseEventsLimit) => {
    if (isUserLoggedIn()) {
        return database.collectionGroup(FRIEND_EVENTS_COL).orderBy('date', 'desc').where("owner.userId", "==", userID).limit(limit).get();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }
}

/**
 * Vrati dalsich {limit} udalosti - friend actions
 * @param {String} userID Id prihlaseneho uzivatela alebo mojho priatela
 * @param {Object} startAfterDoc Doc poslednej udalosti
 * @param {Number} limit limit kolko udalosti chceme nacitat
 */
export const startSetMyEventsAfter = (userID, startAfterDoc, limit = firebaseEventsLimit) => {
    if (isUserLoggedIn()) {
        return database.collectionGroup(FRIEND_EVENTS_COL).orderBy('date', 'desc').where("owner.userId", "==", userID).startAfter(startAfterDoc).limit(limit).get();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }
}

/**
 * Dispatch action pre nacitanie mojich udalosti - verejnych akcii
 * @param {Array} eventsData pole dat o udalosti
 */
export const setMySportEvents = ( eventsData ) => ({
    type: SET_MY_SPORT_EVENTS,
    eventsData
});

/**
 * Dispatch action pre resetovanie nacashovanych udalosti
 * @param {Boolean} cashed priznak, ci chceme aby sme brali udalosti z cashe alebo nacitali nove
 */
export const resetMySportEventsCashed = ( cashed = false ) => ({
    type: RESET_MY_SPORT_EVENTS_CASHED,
    cashed
});

/**
 * Vrati prvych {limit} udalosti verejne - sport events
 * @param {Number} limit kolko udalosti chceme nacitat
 */
export const startSetMySportEventsFirst = (limit = firebaseEventsLimit) => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        return database.collection('events').doc('actions').collection('sport_actions').orderBy('date', 'desc').where("owner.userId", "==", currentUser.uid).limit(limit).get();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }    
}

/**
 * Vrati dalsich {limit} udalosti - sport actions
 * @param {Object} startAfterDoc Doc poslednej nacitanej udalosti
 * @param {Number} limit kolko udalosti chceme vratit
 */
export const startSetMySportEventsAfter = (startAfterDoc, limit = firebaseEventsLimit) => {
    const user = firebase.auth().currentUser;

    if (user !== null && user.emailVerified) {
        return database.collection('events').doc('actions').collection('sport_actions').orderBy('date', 'desc').where("owner.userId", "==", user.uid).startAfter(startAfterDoc).limit(limit).get();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }
}

/**
 * Vrati data o verejnej udalosti
 * @param {String} eventId Id udalosti
 */
export const startGetPublicEvent = ( eventId ) => {   
    if (isUserLoggedIn()) {
        return database.collection('events').doc('public').collection(FRIEND_EVENTS_COL).doc(eventId).get();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }
};

/**
 * Vrati data o privatnej udalosti
 * @param {String} eventId Id udalosti
 */
export const startGetPrivateEvent = ( eventId ) => {   
    if (isUserLoggedIn()) {
        return database.collection('events').doc('private').collection(FRIEND_EVENTS_COL).doc(eventId).get();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }
};

/**
 * Vrati referenciu danej udalosti - friend action
 * @param {String} eventId Id udalosti, ktorej chceme referenciu
 * @param {String} access pristupnost akcie
 */
export const startGetEventReference = ( eventId, access ) => {
    return database.collection('events').doc(access).collection(FRIEND_EVENTS_COL).doc(eventId);
};

/**
 * Vrati prvych {limit} udalosti daneho uzivatela - verejnych friends
 * @param {String} userID Id uzivatelho ktoreho chceme udalosti
 * @param {Number} limit kolko udalosti chceme
 */
export const startGetPublicUserEventsFirst = (userID, limit = firebaseEventsLimit) => {
    if (isUserLoggedIn()) {
        return database.collection('events').doc('public').collection(FRIEND_EVENTS_COL).where('owner.userId', '==', userID).limit(limit).get();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }
}

/**
 * Vrati dalsich {limit} udalosti - verejnych friends
 * @param {String} userID Id uzivatela, ktoreho chceme udalosti
 * @param {Object} startAfterDoc Doc poslednej udalosti
 * @param {Number} limit kolko dalsich udalosti chceme
 */
export const startGetPublicUserEventsAfter = (userID, startAfterDoc, limit = firebaseEventsLimit) => {
    if (isUserLoggedIn()) {
        return database.collection('events').doc('public').collection(FRIEND_EVENTS_COL).where('owner.userId', '==', userID).startAfter(startAfterDoc).limit(limit).get();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }    
}

/**
 * Vrati data o sportovej verejnej udalosti
 * @param {String} eventId Id udalosti
 */
export const startGetSportEvent = (eventId) => {
    return database.collection('events').doc('actions').collection('sport_actions').doc(eventId).get();
}

/**
 * Dispatch action pre nastavenie typu udalosti v moje udalosti
 * @param {String} eventType typ udalosti v sekcii moje udalosti - friend alebo sport
 */
export const setMyEventsType = (eventType) => ({
    type: SET_MY_EVENTS_TYPE,
    eventType
})

/**
 * Dispatch action pre nastavenie mojich udalosti na ktorych sa zucastnim
 * @param {Array} eventsData Pole dat o udalosti
 */
export const setMyJoinedEvents = ( eventsData ) => ({
    type: SET_MY_JOINED_EVENTS,
    eventsData
});

/**
 * Dispatch action pre cashovanie udalosti na ktore sa zucastnim
 * @param {Boolean} cashed priznak ci sa maju cashovat udalosti na ktore sa zucastnim
 */
export const resetMyJoinedEventsCashed = ( cashed = false ) => ({
    type: RESET_MY_JOINED_EVENTS_CASHED,
    cashed
})

/**
 * Nastavi udalosti ktorych sa zucastnim
 */
export const startSetMyJoinedEvents = () => {
    const user = firebase.auth().currentUser;

    if (user !== null && user.emailVerified) {
        return (dispatch) => {
            return database.collectionGroup('participants').where('userID', '==', user.uid).get().then((snapShot) => {
                let events = []; 
                snapShot.forEach((doc) => {   
                    const evID = doc.data().eventInfo && doc.data().eventInfo.eventId ? doc.data().eventInfo.eventId : doc.id          
                    events.push({
                        eventId: evID,
                        ...doc.data()
                    });
                });
    
                dispatch(setMyJoinedEvents(events.reverse()));
            }).catch((err) => {
                return Promise.reject(err);
            });
        }
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }    
}

/**
 * Posle ziadost o pridanie sa na udalosti - notifikacia a pridanie ako pending participant
 * @param {String} userEventId Id uzivatela, ktory vytvoril udalost na ktoru sa chcem pripojit
 * @param {String} eventId Id udalosti
 * @param {String} access pristupnost udalosti
 * @param {Object} notificationData Data pre notifikaciu uzivatelovi patriacemu udalost
 * @param {Object} userInfoData Info o prihlasenom uzivatelovi, prida sa do participants ako PENDING
 */
export const startJoinToUserEvent = (userEventId, eventId, access, notificationData, userInfoData) => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        const batch = database.batch();

        const eventNotifReference = startGetEventNotificationReference(userEventId, eventId);
        batch.set(eventNotifReference, notificationData);
    
        const participantRef = startGetParticipantsReference(eventId, access, currentUser.uid);
        batch.set(participantRef, userInfoData);
    
        return batch.commit();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }    
}

/**
 * Vymaze udalost z mojich udalosti, ktorych sa zucastnim a zaroven vymaze notifikaciu o pridani sa na udalost
 * @param {String} eventId Id udalosti, ktoru chceme zmazat
 * @param {String} eventOwnerID Id uzivatela, ktory vytvoril udalost
 * @param {String} access pristupnost udalosti
 * @param {String} status Status mojej ziadosti o pridanie
 */
export const startRemoveMyJoinedEvent = (eventId, eventOwnerID, access, status) => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        const batch = database.batch();

        const removeParticipantRef = startGetParticipantsReference(eventId, access, currentUser.uid);
        batch.delete(removeParticipantRef);
    
        if (status === 'PENDING') {
            const eventNotifReference = startGetEventNotificationReference(eventOwnerID, eventId);
            batch.delete(eventNotifReference);
        } else if (status === 'INVITE') {
            const eventNotifInviteReference = startGetEventNotificationReference(currentUser.uid, eventId);
            batch.delete(eventNotifInviteReference);
        }
    
        return batch.commit();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }    
}

/**
 * Prijme uzivatela k udalosti
 * @param {String} eventID ID eventu, ktoreho schvalujeme
 * @param {String} userID ID uzivatela ktory sa chce pridat
 * @param {String} access pristupnost udalosti
 * @param {String} userAcceptedId ID prihlaseneho uzivatela
 * @param {Object} userAcceptedData Data o prihlasenom uzivatelovi
 */
export const startAcceptUserToMyEvent = (eventID, userID, access, userAcceptedId, userAcceptedData) => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        return checkIfUserIsPendingParticipant(userID, eventID, access).then((participantData) => {
            if (participantData.exists) {
                const batch = database.batch();

                const participantRef = startGetParticipantsReference(eventID, access, userID);
                batch.update(participantRef, {
                    status: 'ACCEPTED'
                });
            
                const addEventNotifReference = startGetEventNotificationReference(userID, eventID);
                batch.set(addEventNotifReference, userAcceptedData);
            
                const removeEventNotifReference = startGetEventNotificationReference(userAcceptedId, eventID);
                batch.delete(removeEventNotifReference);
            
                return batch.commit();
            } else {
                return startRejectUserToMyEvent(userID, eventID, access).then(() => {
                    return Promise.reject({
                        status: 'user-deleted',
                        msg: 'Žádost o přidání k události byla vymazána, protože uživatel neexistuje !'
                    });
                }).catch(() => {
                    return Promise.reject({
                        status: 'user-deleted',
                        msg: 'Žádost o přidání k události byla vymazána, protože uživatel neexistuje !'
                    });
                }) 
            }
        })
        
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }    
}

/**
 * Odmietne uzivatela k udalosti
 * @param {String} userSendID Id uzivatela ktory poslal ziadost o pridanie
 * @param {String} eventID Id udalosti
 * @param {String} access pristupnost udalosti
 */
export const startRejectUserToMyEvent = (userSendID, eventID, access) => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        const batch = database.batch();

        const participantReferenceDel = startGetParticipantsReference(eventID, access, userSendID);
        batch.delete(participantReferenceDel);
    
        const eventNotifReference = startGetEventNotificationReference(currentUser.uid, eventID);
        batch.delete(eventNotifReference);
    
        return batch.commit();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }    
}

/**
 * Prijatie pozvanky na udalost - pri vytvarani udalosti moze poslat pozvanku
 * @param {String} eventID ID eventu
 * @param {String} access pristupnost udalosti
 */
export const acceptUserInvite = (eventID, access) => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        const batch = database.batch();
        batch.update(startGetParticipantsReference(eventID, access, currentUser.uid), {
            status: 'ACCEPTED'
        });
        batch.delete(startGetEventNotificationReference(currentUser.uid, eventID));
        return batch.commit();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }    
}

/**
 * Odmietnutie pozvania na udalost
 * @param {String} eventID ID udalosti
 * @param {String} access pristupnost udalosti
 */
export const rejectUserInvite = (eventID, access) => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        const batch = database.batch();

        batch.delete(startGetParticipantsReference(eventID, access, currentUser.uid));
        batch.delete(startGetEventNotificationReference(currentUser.uid, eventID))
        return batch.commit();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }    
}

/**
 * 
 * EVENTS MESSAGES
 * 
 */

 /**
  * Vytvori novu spravu pre udalost
  * @param {String} access Pristupnost udalosti
  * @param {String} eventID Id udalosti
  * @param {Object} messageInfo Data o sprave
  */
export const startAddEventMessage = (access, eventID, messageInfo, owner) => {
    const currentUser = firebase.auth().currentUser;
    
    if (currentUser !== null && currentUser.emailVerified) {
        if (owner) {
            return startCheckIfUserIsEventOwner(eventID, access).then((eventData) => {
                if (eventData.exists) {
                    if (eventData.data().owner['userId'] === currentUser.uid) {
                        return database.collection('events').doc(access).collection(FRIEND_EVENTS_COL).doc(eventID).collection('messages').add(messageInfo);
                    } else {
                        return Promise.reject('Uzivatel neni vlastnik');
                    }                     
                } else {
                    return Promise.reject('Uzivatel neni vlastnik');
                } 
            }).catch((err) => {
                return Promise.reject(err)
            })
        } else {
            return startCheckIfUserIsParticipant(eventID, access).then((participantData) => {
                if (participantData.exists && participantData.data().status === 'ACCEPTED') {
                    return database.collection('events').doc(access).collection(FRIEND_EVENTS_COL).doc(eventID).collection('messages').add(messageInfo);
                } else {
                    return Promise.reject('Uzivatel neni ucastnik');
                }
            }).catch((err) => {
                return Promise.reject(err)
            })
        }
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }
}

/**
 * Aktualizuje spravu pre udalost
 * @param {String} access Pristupnost udalosti
 * @param {String} eventID Id udalosti
 * @param {String} messageId Id spravy ktoru chceme editovat
 * @param {String} updateMessage Nova sprava
 */
export const startUpdateEventMessage = (access, eventID, messageId, updateMessage) => {
    const currentUser = firebase.auth().currentUser;
    
    if (currentUser !== null && currentUser.emailVerified) {
        return startCheckIfUserAddMessage(access, eventID, messageId).then((messageData) => {
            if (messageData.exists && messageData.data().userID === currentUser.uid) {
                return database.collection('events').doc(access).collection(FRIEND_EVENTS_COL).doc(eventID).collection('messages').doc(messageId).update({
                    message: updateMessage,
                    edited: true
                });
            } else {
                return Promise.reject('Uzivatel neni autor');
            }
        }).catch((err) => {
            return Promise.reject(err)
        })
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }
}

/**
 * Odstrani spravu pre udalost
 * @param {String} access Pristupnost udalosti
 * @param {String} eventID Id udalosti
 * @param {String} messageId Id spravy ktoru chceme odstranit
 * @param {String} updateMessage Nova sprava
 */
export const startRemoveEventMessage = (access, eventID, messageId) => {
    const currentUser = firebase.auth().currentUser;
    
    if (currentUser !== null && currentUser.emailVerified) {
        return  startCheckIfUserAddMessage(access, eventID, messageId).then((messageData) => {
            if (messageData.exists && messageData.data().userID === currentUser.uid) {
                return database.collection('events').doc(access).collection(FRIEND_EVENTS_COL).doc(eventID).collection('messages').doc(messageId).delete();
            } else {
                return Promise.reject('Uzivatel neni autor');
            }
        }).catch((err) => {
            return Promise.reject(err)
        })
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }
}

/**
 * Vrati prvych {limit} sprav k udalosti
 * @param {String} access Pristupnost udalosti
 * @param {String} eventID Id udalosti
 * @param {Number} limit Limit pocet prvych {limit} sprav
 */
export const startGetEventMessagesFirst = (access, eventID, limit = eventMessagesLimit) => {
    if (isUserLoggedIn()) {
        return database.collection('events').doc(access).collection(FRIEND_EVENTS_COL).doc(eventID).collection('messages').orderBy('date', 'desc').limit(limit).get();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }
}

/**
 * Vrati dalsich {limit} sprav k udalosti
 * @param {String} access Pristupnost udalosti
 * @param {String} eventID Id udalosti
 * @param {Object} startAfterDoc Udaj poslednej nacitanej spravy
 * @param {Number} limit Pocet sprav ktore sa maju zobrazit
 */
export const startGetEventMessagesAfter = (access, eventID, startAfterDoc, limit = eventMessagesLimit) => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        return database.collection('events').doc(access).collection(FRIEND_EVENTS_COL).doc(eventID).collection('messages').orderBy('date', 'desc').startAfter(startAfterDoc).limit(limit).get();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }    
}

/**
 * Vrati danu spravu
 * @param {String} access Pristupnost udalosti
 * @param {String} eventID Id udalosti
 * @param {String} messageId Id spravy
 */
const startCheckIfUserAddMessage = (access, eventID, messageId) => {
    const currentUser = firebase.auth().currentUser;
    
    if (currentUser !== null && currentUser.emailVerified) {
        return database.collection('events').doc(access).collection(FRIEND_EVENTS_COL).doc(eventID).collection('messages').doc(messageId).get();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }
}

/**
 * 
 * PARTICIPANTS ----------------------------------------------------------
 * 
 */

 /**
  * Dispatch action pre nastavenie ucastnikov udalosti
  * @param {Array} participants Pole ucastnikov udalosti
  */
export const setEventParticipants = ( participants = [] ) => ({
    type: SET_EVENT_PARTICIPANTS,
    participants
});

/**
 * Dispatch action pre resetovanie pola ucastnikov
 */
export const resetEventParticipants = () => ({
    type: RESET_EVENT_PARTICIPANTS
});

/**
 * Skontroluje ci uzivatel je ucastnik danej udalosti
 * @param {String} eventId Id udalosti
 * @param {String} access Pristupnost udalosti 
 */
export const startCheckIfUserIsParticipant = (eventId, access) => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        return database.collection('events').doc(access).collection(FRIEND_EVENTS_COL).doc(eventId).collection('participants').doc(currentUser.uid).get();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }    
}

export const startCheckIfUserIsEventOwner = (eventId, access) => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        return database.collection('events').doc(access).collection(FRIEND_EVENTS_COL).doc(eventId).get();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }  
}

/**
 * Kontrola ci uzivatel naozaj poslal ziadost o pridanie na akciu, ci je pending participant
 * @param {String} userID Id pouzivatela, ktoreho chceme skontrolovat ci je pending participant
 * @param {String} eventID Id eventu
 * @param {String} access Pristupnost eventu
 */
const checkIfUserIsPendingParticipant = (userID, eventID, access) => {
    return database.collection('events').doc(access).collection(FRIEND_EVENTS_COL).doc(eventID).collection('participants').doc(userID).get();
}

/**
 * Vrati vsetkych ucastnikov udalosti
 * @param {String} eventId Id udalosti
 * @param {String} access Pristupnost udalosti
 */
export const startGetAllParticipants = (eventId, access) => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        return database.collection('events').doc(access).collection(FRIEND_EVENTS_COL).doc(eventId).collection('participants').get();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }    
}

/**
 * Vrati prvych {limit} ucastnikov udalosti
 * @param {String} eventId Id udalosti
 * @param {String} access Pristupnost udalosti
 * @param {Number} limit Kolko chceme ziskat ucastnikov
 */
export const startGetAllParticipantsPaginationFirst = (eventId, access, limit = participantsLimit) => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        return database.collection('events').doc(access).collection(FRIEND_EVENTS_COL).doc(eventId).collection('participants').limit(limit).get();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }    
}

/**
 * Vrati dalsich {limit} ucastnikov
 * @param {String} eventId Id udalosti
 * @param {String} access Pristupnost udalosti
 * @param {Object} startAfterDoc Doc poslednej nacitanej udalosti
 * @param {Number} limit Kolko chcem dalsich ucastnikov
 */
export const startGetAllParticipantsPaginationAfter = (eventId, access, startAfterDoc, limit = participantsLimit) => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        return database.collection('events').doc(access).collection(FRIEND_EVENTS_COL).doc(eventId).collection('participants').startAfter(startAfterDoc).limit(limit).get();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }    
}

/**
 * Vrati Referenciu na vybraneho ucastnika
 * @param {String} eventId Id udalosti
 * @param {String} access Prisupnost udalosti
 * @param {String} userId Id ucastnika, ktoreho chceme ziskat
 */
export const startGetParticipantsReference = (eventId, access, userId) => {
    return database.collection('events').doc(access).collection(FRIEND_EVENTS_COL).doc(eventId).collection('participants').doc(userId);
}


/**
 * 
 * FRIENDS -------------------------------------------------------
 * 
 */

 /**
  * Vrati referenciu vybraneho priatela
  * @param {String} myID Id prihlaseneho uzivatela
  * @param {String} friendID Id priatela, ktoreho chceme ref
  */
 export const startGetFriendReference = (myID, friendID) => {
    return database.collection('friends').doc(myID).collection('friends_list').doc(friendID);
 }

 
 /**
  * Funkcia, ktora akceptuje uzivatela ako priatela - prijme friend request
  * @param {String} userSendRequestID ID uzivatela, ktory poslal friend request
  * @param {Object} userLoginInfo Informacie a prihlasenom uzivatelovi
  */
export const startAcceptFriend = (userSendRequestID, userLoginInfo = {}, userSendInfo = {}) => {    
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        return checkFriendRequestSend(userSendRequestID).then((friendRequestData) => {   
            if (!friendRequestData.empty) {
                return startGetMyUser(userSendRequestID).then(userData => {
                    if (userData.exists) {
                        const batch = database.batch();
        
                        const loginUserRef = startGetFriendReference(currentUser.uid, userSendRequestID);
                        const userSendInfoWithDate = Object.assign({}, userSendInfo, { 
                            date: moment().valueOf()
                        })
                        batch.set(loginUserRef, userSendInfoWithDate);
            
                        const acceptUserRef = startGetFriendReference(userSendRequestID, currentUser.uid);
                        const userLoginInfoWithDate = Object.assign({}, userLoginInfo, {
                            date: moment().valueOf()
                        })
                        batch.set(acceptUserRef, userLoginInfoWithDate);
            
                        const deleteFriendNotifRefer = startGetFriendNotificationReference(currentUser.uid, userSendRequestID);
                        batch.delete(deleteFriendNotifRefer);
            
                        const sendFriendNotifRef = startGetFriendNotificationReference(userSendRequestID, currentUser.uid);
                        batch.set(sendFriendNotifRef, {
                            name: userLoginInfo.name,
                            photo: userLoginInfo.photo,
                            about: 'CONFIRMATION_FRIEND_REQUEST',
                            fromUserId: userLoginInfo.userID,
                            date: moment().valueOf()
                        })
            
                        const loginFriendReqRef = startGetFriendRequestReference(userSendRequestID, currentUser.uid);
                        batch.delete(loginFriendReqRef);
            
                        const acceptFriendReqRef = startGetFriendRequestReference(currentUser.uid, userSendRequestID);
                        batch.delete(acceptFriendReqRef);
            
                        return batch.commit();
                    } else {
                        return startRejectFriend(userSendRequestID).then(() => {
                            return Promise.reject('Žádost o přátelství byla vymazána, protože uživatel neexistuje !');
                        }).catch(() => {
                            return Promise.reject('Uzivatel neexistuje ! Odstraňte žádost !');
                        }) 
                    }
                })    
            } else {
                startRejectFriend(userSendRequestID).then(() => {
                    return Promise.reject('Žádost o přátelství byla vymazána, protože uživatel neposlal friend request !');
                }).catch(() => {
                    return Promise.reject('Uzivatel neposlal friend request !');
                })                
            }
        })
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }   
}

/**
 * Odmietneme uzivatela o priatelstvo
 * @param {Number} userSendRequestID ID uzivatela, ktory poslal friend request
 */
export const startRejectFriend = (userSendRequestID) => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        const batch = database.batch();

        const loginFriendReqRef = startGetFriendRequestReference(userSendRequestID, currentUser.uid);
        batch.delete(loginFriendReqRef);
    
        const acceptFriendReqRef = startGetFriendRequestReference(currentUser.uid, userSendRequestID);
        batch.delete(acceptFriendReqRef);
    
        const deleteFriendNotifRefer = startGetFriendNotificationReference(currentUser.uid, userSendRequestID);
        batch.delete(deleteFriendNotifRefer);
    
        return batch.commit();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }      
}

/**
 * Odstrani ziadost o priatelstvo
 * @param {String} userSendRequestID Id uzivatela, ktory poslal friend request
 */
export const startRejectFriendRequest = (userSendRequestID) => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        const batch = database.batch();

        const loginFriendReqRef = startGetFriendRequestReference(currentUser.uid, userSendRequestID);
        batch.delete(loginFriendReqRef);
    
        const acceptFriendReqRef = startGetFriendRequestReference(userSendRequestID, currentUser.uid);
        batch.delete(acceptFriendReqRef);
    
        const deleteFriendNotifRefer = startGetFriendNotificationReference(userSendRequestID, currentUser.uid);
        batch.delete(deleteFriendNotifRefer);
    
        return batch.commit();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }   
}

/**
 * Vrati ci uzivatel poslal friend request
 * @param {String} userID Id uzivatela, ktoreho kontrolujeme ci mi poslal friend request
 */
export const startGetFriendRequest = (userID) => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        return database.collection('friends_requests').doc(currentUser.uid).collection('friends_requests_list').doc(userID).get();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }       
}

/**
 * Vrati ci uzivatel je moj kamarat
 * @param {String} friendID Id uzivatela, u ktoreho chcem zistit ci je moj kamos
 */
export const startCheckIfUserIsFriend = (friendID) => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        return database.collection('friends').doc(currentUser.uid).collection('friends_list').doc(friendID).get();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }      
}

/**
 * Odstrani uzivatela z priatelov
 * @param {String} cancelUserId Id uzivatela, ktoreho chceme odstranit z priatelov
 */
export const startCancelFriendship = (cancelUserId) => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        const batch = database.batch();

        const friendRef = startGetFriendReference(currentUser.uid, cancelUserId);
        batch.delete(friendRef);

        const myRef = startGetFriendReference(cancelUserId, currentUser.uid);
        batch.delete(myRef);

        return batch.commit();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }     
}

/**
 * Dispatch action, ktora prida do store priatelov
 * @param {Array} friends Pole priatelov
 */
export const getFriends = (friends = []) => ({
    type: GET_FRIENDS,
    friends
});

/**
 * Resetuje nacashovanie priatelov
 * @param {Boolean} cashed Priznak, ci sa maju priatelia nacashovat
 */
export const resetMyFriendsCashed = ( cashed = false ) => ({
    type: RESET_MY_FRIENDS_CASHED,
    cashed
});

/**
 * Dispatch action pre nastavenie pozvanych priatelov do store
 * @param {Array} friend Pole pozvanych priatelov pri vytvarani udalosti
 */
export const setInviteFriend = (friend = []) => ({
    type: SET_INVITE_FRIEND,
    friend
});

/**
 * Dispatch action pre vymazanie pozvaneho priatela
 * @param {Object} friend Priatel ktoreho chceme odstranit z pozvanky
 */
export const removeInvitedFriend = (friend = {}) => ({
    type: REMOVE_INVITE_FRIEND,
    friend
})

/**
 * Dispatch action, ktora resetuje pozvanych priatelov
 */
export const resetInviteFriend = () => ({
    type: RESET_INVITE_FRIEND
});

/**
 * Vrati prvych {limit} priatelov
 * @param {String} userID Id prihlaseneho uzivatela
 * @param {Number} limit Kolko uzivatelov chcem nacitat
 */
export const startGetMyFriendsFirst = (limit = participantsLimit) => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        return database.collection('friends').doc(currentUser.uid).collection('friends_list').limit(limit).get();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }      
}

/**
 * Vrati dalsich {limit} priatelov
 * @param {Object} startAfterDoc Doc posledneho nacitaneho priatela
 * @param {Number} limit Kolko chcem nacitat dalsich priatelov
 */
export const startGetMyFriendsAfter = (startAfterDoc, limit = participantsLimit) => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        return database.collection('friends').doc(currentUser.uid).collection('friends_list').startAfter(startAfterDoc).limit(limit).get();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }      
}

/**
 * Overime ci uzivatel mi naozaj poslal friend request, zistime to podla toho ci mu prisiel friend request
 * @param {String} userSendRequestID id ktory posiela request
 */
export const checkFriendRequestSend = (userSendRequestID) => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        return database.collection('friends_requests').doc(currentUser.uid).collection('friends_requests_list').where('fromUserId', '==', userSendRequestID).get();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }    
}

/**
 * Vrati ref friend requestu
 * @param {String} requestUserID Id uzivatela, ktoremu posielam friend request
 * @param {String} loginUserID Id prihlaseneho uzivatela
 */
export const startGetFriendRequestReference = (requestUserID, loginUserID) => {
    return database.collection('friends_requests').doc(loginUserID).collection('friends_requests_list').doc(requestUserID);
}

/**
 * 
 * NOTIFICATIONS ----------------------------------------------------------
 * 
 */

 /**
  * Dispatch action pre otvorenie udalosti aby sa nezavrela ked na nu kliknes => 
  * ClickAwayListener notifications
  * @param {Boolean} isOpen Je otvoreny detail pre notifikaciu udalosti
  */
export const openEventNotification = (isOpen) => ({
    type: OPEN_EVENT_NOTIFICATION,
    isOpen
});

/**
 * Dispatch action pre nacashovanie notifikacii pre udalosti
 * @param {Boolean} cashed Priznak ci su notifikacie nacashovane
 */
export const cashedEventsNotifications = (cashed) => ({
    type: CASHED_EVENTS_NOTIFICATIONS,
    cashed
});

/**
 * Dispatch action pre nacashovanie notifikacii pre priatelov
 * @param {Boolean} cashed Priznak ci su notifikacie nacashovane
 */
export const cashedFriendEventsNotifications = (cashed) => ({
    type: CASHED_FRIEND_EVENTS_NOTIFICATIONS,
    cashed
});

/**
 * Dispatch action pre notifikacie udalosti
 * @param {Array} notifications Pole notifikacii - udalosti
 */
export const getEventsNotifications = ( notifications ) => ({
    type: GET_EVENTS_NOTIFICATIONS,
    notifications
});

/**
 * Ziska notifikacie udalosti
 */
export const startSetEventsNotifications = () => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        return (dispatch) => {
            const unsubscribe = database.collection('notifications').doc(currentUser.uid).collection('events').limit(notificationsLimit).onSnapshot((dataSnapshot) => {
                let notifications = [];
                dataSnapshot.forEach((doc) => {
                    notifications.push({
                        notificationID: doc.id,
                        docKey: doc,
                        ...doc.data()
                    });
                });
    
                dispatch(getEventsNotifications(notifications));
                dispatch(cashedEventsNotifications(true));
            }, (err) => {
                return Promise.reject(err);
            });
            return unsubscribe
        }
    } else {
        return Promise.reject('Uzivatel neexistuje !');
    }
}

/**
 * Vrati Ref notifikacie pre udalost
 * @param {String} userId Id uzivatela koho notifikaciu chceme  
 * @param {String} eventId Id udalosti
 */
export const startGetEventNotificationReference = (userId, eventId) => {
    return database.collection('notifications').doc(userId).collection('events').doc(eventId);
}

/**
 * Dispatch action pre notifikacie priatelov
 * @param {Array} notifications Pole notifikacii - priatelia
 */
export const getFriendNotifications = ( notifications ) => ({
    type: GET_FRIEND_NOTIFICATIONS,
    notifications
});

/**
 * Ziska vsetky notifikacie - priatelia
 * 
 */
export const startSetFriendNotifications = () => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        return (dispatch) => {
            const unsubscribe =  database.collection('notifications').doc(currentUser.uid).collection('friends').limit(notificationsLimit).onSnapshot((dataSnapshot) => {
                let notifications = [];
    
                dataSnapshot.forEach((doc) => {
                    notifications.push({
                        notificationID: doc.id,
                        docKey: doc,
                        ...doc.data()
                    });
                });
    
                dispatch(getFriendNotifications(notifications));
                dispatch(cashedFriendEventsNotifications(true));
            }, (err) => {
                return Promise.reject(err)
            });   
            
            return unsubscribe;
        }
    } else {
        return Promise.reject('Uzivatel neexistuje !');
    }    
}

/**
 * Vrati Ref notifikacie - priatela
 * @param {String} userID Id uzivatela, od ktoreho chceme ziskat notikaciu
 * @param {String} userSendID Id uzivatela, ktory poslal notifikaciu
 */
export const startGetFriendNotificationReference = (userID, userSendID) => {
    return database.collection('notifications').doc(userID).collection('friends').doc(userSendID);
}

/**
 * Odstrani notifikaciu
 * @param {String} type Typ notifikacie = friends / events
 * @param {String} notificationID Id notifikacie
 */
export const startDeleteNotification = (type, notificationID) => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        return database.collection('notifications').doc(currentUser.uid).collection(type).doc(notificationID).delete();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }     
}

/**
 * Vrati dalsich {limit} notifikacii
 * @param {String} type Typ notifikacie friends / events
 * @param {Object} startAfterDoc Doc reference poslednej notifikacie
 * @param {Number} limit Limit kolko dalsich notifikacii sa ma nacitat
 */
export const startGetNotificationsAfter = (type, startAfterDoc, limit = notificationsLimit) => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        return database.collection('notifications').doc(currentUser.uid).collection(type).startAfter(startAfterDoc).limit(limit).get();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }      
}

/**
 * Dispatch action pre vymazanie notifikacie pre udalosti
 * @param {String} id Id notifikacie
 */
export const deleteEventNotification = (id) => ({
    type: DELETE_EVENT_NOTIFICATION,
    id
});

/**
 * Dispatch action pre vymazanie notifikacie pre friends
 * @param {String} id Id notifikacie
 */
export const deleteFriendNotification = (id) => ({
    type: DELETE_FRIEND_NOTIFICATION,
    id
});


/**
 * 
 * Searching ------------------------------------------------------------
 * 
 */

/**
 * Dispatch action pre nacitanie hladanych uzivatelov
 * @param {Array} usersData Pole data o hladanych uzivatelov
 */
export const getSearchUsers = (usersData) => ({
    type: SEARCH_USERS,
    usersData
});

/**
 * Dispatch action pre resetovanie hladanych uzivatelov
 */
export const resetSearchUsers = () => ({
    type: RESET_SEARCH_USERS
});

/**
 * Vyhladavanie uzivatelov podla mena, vrati prvych 5 vysledkov
 * @param {String} name Meno hladaneho uzivatela
 */
export const searchUserName = (name) => {    
    if (isUserLoggedIn()) {
        return database.collection('users').orderBy('nameLower').where('nameLower', '>=', name).where('nameLower', '<=', name+'\uf8ff').limit(usersSearchLimit).get();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }   
}

/**
 * 
 * @param {String} name Meno hladaneho uzivatela
 * @param {Object} startAfterDoc Doc posledneho nacitaneho uzivatela
 * @param {Number} limit Kolko chcem dalsich uzivatelov
 */
export const searchUserNamePagination = (name, startAfterDoc, limit = usersSearchLimit) => {
    if (isUserLoggedIn()) {
        return database.collection('users').orderBy('nameLower').where('nameLower', '>=', name).where('nameLower', '<=', name+'\uf8ff').startAfter(startAfterDoc).limit(limit).get();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }
}

/**
 * 
 * STORAGE ---------------------------------------------------------
 * 
 */

 /**
  * Ulozi do storage foto udalosti
  * @param {String} photo Fotka udalosti
  * @param {String} eventId Id udalosti
  * @param {String} photoName Nazov fotky
  */
export const startSaveEventPhoto = (photo, eventId, photoName, userId = '') => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser !== null && currentUser.emailVerified) {
        const storageRef = firebase.storage().ref('users').child(currentUser.uid).child('eventsPhotos').child(eventId).child(photoName);
        const task = storageRef.putString(photo, 'data_url', {customMetadata: {"owner": userId}});
        return task;
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }  
}

/**
 * Vymaze fotky zo storage
 * @param {String} photoURL Url fotky
 */
export const removePhotoFromStorage = (photoURL) => {
    if (isUserLoggedIn()) {
        const storageRef = firebase.storage().refFromURL(photoURL);
        return storageRef.delete();
    } else {
        return Promise.reject('Uzivatel neexistuje');
    }
}

/**
 * Send email
 */

export const sendContactEmail = functions.httpsCallable('sendContactEmail');

/**
 * PWA OFFLINE
 */

export const isUserOnline = ( online = true ) => ({
    type: USER_ONLINE,
    online
});