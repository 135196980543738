import React from 'react';
import { Link } from 'react-router-dom';
import AccountCircle from '@material-ui/icons/AccountCircle';
import IconButton from '@material-ui/core/IconButton';

const styles = {
    flexGrow: 1,
    textAlign: 'right'
}

export class UserLogout extends React.PureComponent {
    constructor(props) {
        super(props);       
    }

    render() {
    
        return (
            <div style={styles} className="header__user">
                <Link to="/login">Přihlášení</Link>
                <IconButton
                component={Link} 
                to="/login" 
                className="header__user-icon-button"               
                >
                    <AccountCircle className="header__user-icon" /> 
                </IconButton>                                                 
            </div>
        )
    }
} 

export default UserLogout;