import { FETCH_CENTRUMS_BEGGINS, FETCH_CENTRUMS_FAILED, FETCH_CENTRUMS_SUCCESS,
        FETCH_CENTRUM_DETAIL_BEGGINS, FETCH_CENTRUM_DETAIL_FAILED, FETCH_CENTRUM_DETAIL_RESET,
        FETCH_CENTRUM_DETAIL_SUCCESS } from '../actions/centrumsActions';

const initialState = {
    centrums: {},
    centrumDetail: [],
    dataListLoaded: false,
    dataDetailLoaded: false,
    error: null
}

export const centrumsReducer = ( state = initialState, action ) => {
    switch(action.type) {
        case FETCH_CENTRUMS_BEGGINS:
            return {
                ...state,
                dataListLoaded: false,
                error: null,
                centrums: {}
            };

        case FETCH_CENTRUM_DETAIL_BEGGINS:
            return {
                ...state,
                dataDetailLoaded: false,
                error: null
            }; 

        case FETCH_CENTRUMS_SUCCESS:
            return {
                ...state,
                dataListLoaded: true,
                centrums: {...action.payload.centrums} || {}
            }

        case FETCH_CENTRUM_DETAIL_SUCCESS:
            return {
                ...state,
                dataDetailLoaded: true,
                centrumDetail: action.payload.centrum || []
            }

        case FETCH_CENTRUMS_FAILED: 
            return {
                ...state,
                dataListLoaded: true,
                error: action.payload.error,
                centrums: {}
            };

        case FETCH_CENTRUM_DETAIL_FAILED:
            return {
                ...state,
                dataDetailLoaded: true,
                error: action.payload.error,
                centrumDetail: []
            }

        case FETCH_CENTRUM_DETAIL_RESET:
            return {
                ...state,
                dataDetailLoaded: false,
                error: null,
                centrumDetail: []
            }

        default:
            return state
    }
}