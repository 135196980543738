import { GET_PUBLIC_EVENTS, RESET_EVENTS, GET_USERS_SPORT_ACTIONS } from '../actions/sportsActions';

const initialState = {
    public: {
        sports: []
    },
    events: {
        sports: []
    }
}

export const usersSportsReducer = ( state = initialState, action ) => {
    switch(action.type) {
        case GET_PUBLIC_EVENTS:
            return {
                ...state,
                public: {
                    ...state.public,
                    sports: state.public.sports.concat(action.events)
                }
            }
        
        case RESET_EVENTS:
            return {
                ...initialState
            } 

        case GET_USERS_SPORT_ACTIONS:
            return {
                ...state,
                events: {
                    ...state.events,
                    sports: state.events.sports.concat(action.events)
                }
            }

        default:
            return state
    }
}