import { SET_USER_ADDRESS_FILTER, SET_USER_DATE_FROM_FILTER, SET_USER_DATE_TO_FILTER, SET_USER_RADIUS_FILTER, 
    SET_USER_SPORTS_ARRAY_FILTER, SET_USER_SPORTS_DISTANCE, SET_USER_ORDER_FILTER } from '../actions/usersSportsFilterActions';
import moment from 'moment';

// initial state for official filters
const usersSportsFiltersInitialState = {
    sportsArray: '',
    address: {
        user: {
            name: '',
            longitude: 14.47,
            latitude: 50.07,
            zoom: 4,
            defined: false
        }
    },
    dateFrom: moment().startOf('day').locale('sk'),
    dateTo: null,
    radius: '',    
    distance: 0,
    order: 'time'
};

export const usersSportsFiltersReducer = (state = usersSportsFiltersInitialState, action) => {
    switch(action.type){   
        case SET_USER_SPORTS_ARRAY_FILTER:
            return {
                ...state,
                sportsArray: action.sportsArray
            }
            
        case SET_USER_ADDRESS_FILTER:
            return {
                ...state,
                address: {
                    ...state.address,
                    user: {
                        ...state.address.user,
                        ...action.address
                    }                    
                }
            }        

        case SET_USER_DATE_FROM_FILTER:
            return {
                ...state,
                dateFrom: action.dateFrom
            }

        case SET_USER_DATE_TO_FILTER:
            return {
                ...state,
                dateTo: action.dateTo
            }

        case SET_USER_RADIUS_FILTER:
            return {
                ...state,
                radius: action.radius
            }       

        case SET_USER_SPORTS_DISTANCE:
            return {
                ...state,
                distance: action.sportsDistance
            }

        case SET_USER_ORDER_FILTER:
            return {
                ...state,
                order: action.order
            }

        default:
            return state;
    }    
}