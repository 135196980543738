import React from 'react';

const Waiting = (props) => {

    return (
        <div className="waiting-wrapper">  
            <div className="loading">                
            </div>
        </div>
    )
}

export default Waiting;