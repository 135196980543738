export const FETCH_CENTRUMS_BEGGINS = 'FETCH_CENTRUMS_BEGGINS';
export const FETCH_CENTRUMS_SUCCESS = 'FETCH_CENTRUMS_SUCCESS';
export const FETCH_CENTRUMS_FAILED = 'FETCH_CENTRUMS_FAILED';

export const FETCH_CENTRUM_DETAIL_BEGGINS = 'FETCH_CENTRUM_DETAIL_BEGGINS';
export const FETCH_CENTRUM_DETAIL_SUCCESS = 'FETCH_CENTRUM_DETAIL_SUCCESS';
export const FETCH_CENTRUM_DETAIL_FAILED = 'FETCH_CENTRUM_DETAIL_FAILED';
export const FETCH_CENTRUM_DETAIL_RESET = 'FETCH_CENTRUM_DETAIL_RESET';

export const fetchCentrumsBegin = () => ({
    type: FETCH_CENTRUMS_BEGGINS
});

export const fetchCentrumsSuccess = ( centrums ) => ({
    type: FETCH_CENTRUMS_SUCCESS,
    payload: { centrums }
});

export const fetchCentrumsFailed = (error) => ({
    type: FETCH_CENTRUMS_FAILED,
    payload: { error }
});

// centrum detail
export const fetchCentrumDetailBeggin = () => ({
    type: FETCH_CENTRUM_DETAIL_BEGGINS
});

export const fetchCentrumDetailSuccess = ( centrum ) => ({
    type: FETCH_CENTRUM_DETAIL_SUCCESS,
    payload: { centrum }
});

export const fetchCentrumDetailFailed = ( error ) => ({
    type: FETCH_CENTRUM_DETAIL_FAILED,
    payload: { error }
});

export const resetCentrumDetail = () => ({
    type: FETCH_CENTRUM_DETAIL_RESET
});