import React from 'react';
import Button from '@material-ui/core/Button';

class PaginationFirestore extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const { paginationData, dense = false } = this.props;
        const showLoadMore = this._showLoadMore();
       
        if (dense) {
            return (
                <div className="pagination pagination-dense">           
                    {showLoadMore && (
                        <Button 
                        size="small"
                        color="secondary"
                        variant="outlined" 
                        onClick={paginationData}
                        >
                            Načíst víc
                        </Button>
                    )}
                </div>
            )
        } else {
            return (
                <div className="pagination">           
                    {showLoadMore && (
                        <Button 
                        variant="outlined" 
                        onClick={paginationData}
                        >
                            Načíst víc
                        </Button>
                    )}
                </div>
            )
        }
    }

    _showLoadMore() {
        const { items = [], lastVisibleDoc, itemsLimit, showLoadMore, addedLocaly = false, deletedLocaly = false } = this.props;
        let itemsLength = items.length;        

        if (addedLocaly || deletedLocaly) {
            if (showLoadMore && lastVisibleDoc) {
                return true
            } else {
                return false
            }
        } else {
            if (showLoadMore && lastVisibleDoc && itemsLength >= itemsLimit && itemsLength % itemsLimit === 0) {
                return true
            } else {
                return false
            }
        }  
    }
}

export default PaginationFirestore;