import React from 'react';
import UserLogin from './user_component/user_auth/UserLogin';
import UserLogout from './user_component/user_auth/UserLogout';
import { isUserLoggedIn } from '../actions/usersActions';
import { connect } from 'react-redux';

export class User extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const { userID } = this.props;

        if (isUserLoggedIn() && userID) {
            return (
                <UserLogin />
            )
        } else {
            return (
                <UserLogout />
            )
        }
    }
} 

const mapStateToProps = (state) => {
    return {
        userID: state.user.userInfo.SF_USER_UID
    }
}

export default connect(mapStateToProps)(User);