import { SET_CENTRUM_DETAIL_ADDRESS_FILTER, SET_CENTRUM_RADIUS_FILTER, SET_CENTRUM_FILTER, SET_CENTRUMS_DISTANCE,
        SET_CENTRUMS_ORDER } from '../actions/centrumsFilterActions';

const centrumsFilterInitialState = {
    centrum: '',
    address: {
        centrumDetail: {
            name: '',
            longitude: 0,
            latitude: 0,
            zoom: 0
        }
    },
    radius: 0,
    distance: 0,
    order: 'closest'
}

export const centrumsFilterReducer = (state = centrumsFilterInitialState, action) => {
    switch(action.type) {
        case SET_CENTRUM_FILTER:
            return {
                ...state,
                centrum: action.centrum
            }

        case SET_CENTRUM_DETAIL_ADDRESS_FILTER:
            return {
                ...state,
                address: {
                    ...state.address,
                    centrumDetail: {
                        ...state.address.centrumDetail,
                        ...action.address
                    }
                }
            }

        case SET_CENTRUM_RADIUS_FILTER:
            return {
                ...state,
                radius: action.radius
            }

        case SET_CENTRUMS_DISTANCE:
            return {
                ...state,
                distance: action.centrumDistance
            }

        case SET_CENTRUMS_ORDER:
            return {
                ...state,
                order: action.order
            }

        default:
            return state;
    }
}