import React from 'react';
import {NavLink} from 'react-router-dom';

const divStyle = {
    flexGrow: 4
  };

const Navigation = () => {    
    
    return (
    <div style={divStyle}>
        <nav className="header__navigation">
            <NavLink exact={true} activeClassName="active-link" to="/">Home</NavLink>   
            <NavLink exact={true} activeClassName="active-link" to="/sports">Navštívit</NavLink>
            <NavLink activeClassName="active-link" to="/users-sports">Zahrát si</NavLink>     
            <NavLink exact={true} activeClassName="active-link" to="/centrums">Sportovní centra</NavLink>       
        </nav>
    </div>

)};

export default Navigation;