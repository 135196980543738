import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import Clear from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import NotificationsDialog from './NotificationsDialog';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import { startAcceptUserToMyEvent, 
        startRejectUserToMyEvent, 
        openEventNotification, 
        startDeleteNotification } from '../../../actions/usersActions';
import { withSnackbar } from 'notistack';
import { showSnackbar } from '../../../helper_func/SnackBarcCallback';
import { notificationsLimit } from '../../../constants/constants';
import { connect } from 'react-redux';
import { formatDateText } from '../../../helper_func/Format';
import moment from 'moment';

class NotificationsList extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            openDialogID: null
        }

        this.handleDialogOpen = this.handleDialogOpen.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
    }

    
    handleDialogOpen (event) {  
        this.props.dispatch(openEventNotification(true));     
        this.setState({openDialogID: event.currentTarget.id});
    }

    handleCloseDialog() {
        this.props.dispatch(openEventNotification(false));
        this.setState({openDialogID: null});
    }
    
    render() {
    const { eventsNotifications, userCurInfo } = this.props;
    const { openDialogID } = this.state;
      return (
        <div className='notifications-list'>
            <List>                
                { eventsNotifications.length > 0 ? (
                    <React.Fragment>
                        {eventsNotifications.slice(0, notificationsLimit).map((notification) => {
                            const time = notification.date ? formatDateText(notification.date) : '';

                            return (                        
                                <ListItem 
                                key={notification.notificationID} 
                                dense
                                className="list-item"
                                >
                                    <Link to={`/user/${notification.userID}`} >                      
                                        <Avatar src={notification.photo} />                                           
                                    </Link>
                                    {notification.about === 'INVITE' ? (
                                        <ListItemText
                                        primary={<Link className="not-name" to={`/user/${notification.userID}`} >{notification.name}</Link>}
                                        secondary='Vás pozývá na jeho událost!'
                                    /> 
                                    ) : (
                                        <ListItemText
                                        primary={<Link className="not-name" to={`/user/${notification.userID}`} >{notification.name}</Link>}
                                        secondary={notification.about === 'REQUEST' ? 'By se chtěl připojit k vaši události' : 'Přijal vaši žádost!'}
                                    /> 
                                    )} 
                                    {notification.about === 'REQUEST' ? (
                                        <div>
                                            <div className="not-time">{time}</div>
                                            <Button id={notification.notificationID} className="detail-button" size="small" onClick={this.handleDialogOpen}>
                                                Detail
                                            </Button> 
                                            {openDialogID && (
                                                <NotificationsDialog 
                                                {...notification} 
                                                openDialogID={openDialogID} 
                                                handleCloseDialog={this.handleCloseDialog}
                                                userCurInfo={userCurInfo} 
                                                /> 
                                            )}                
                                            <Button color="primary" size="small" onClick={() => {
                                                startAcceptUserToMyEvent(notification.notificationID, notification.userID, notification.access, userCurInfo.SF_USER_UID, {
                                                    about: 'ACCEPT',
                                                    access: notification.access,
                                                    name: userCurInfo.SF_USER_NAME,
                                                    photo: userCurInfo.SF_USER_IMAGE_URL,
                                                    userID: userCurInfo.SF_USER_UID,
                                                    date: moment().valueOf()
                                                }).catch((err) => {
                                                    const status = err && err.status ? err.status : '';
                                                    const msg = err && err.msg ? err.msg : '';
                                                    const snackMsg = status && msg ? msg : 'Nastala chyba při potvrzení uživatele !';
                                                    showSnackbar(this.props.enqueueSnackbar, this.props.closeSnackbar, snackMsg, 'error', err);
                                                })
                                            }}>
                                                Přijat
                                            </Button>
                                            <Tooltip TransitionComponent={Zoom} title='Odstranit'>
                                                <IconButton
                                                onClick={() => {
                                                    startRejectUserToMyEvent(notification.userID, notification.notificationID, notification.access).catch((err) => {
                                                        showSnackbar(this.props.enqueueSnackbar, this.props.closeSnackbar, 'Nastala chyba při odstránení uživatele !', 'error', err);
                                                    })
                                                }}
                                                >
                                                    <Clear fontSize="small" color="error" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    ) : (
                                        <div>
                                            <div className="not-time">{time}</div>
                                            <div>                                                
                                                <Button
                                                component={Link}
                                                className="detail-button"
                                                to={`/event/detail/${notification.notificationID}`}
                                                size="small"
                                                >
                                                    Detail
                                                </Button>
                                                <Tooltip TransitionComponent={Zoom} title='Odstranit upozornění'>
                                                    <IconButton
                                                    onClick={() => {
                                                        startDeleteNotification('events', notification.notificationID).catch((err) => {
                                                            showSnackbar(this.props.enqueueSnackbar, this.props.closeSnackbar, 'Nastala chyba při odstránení upozornění !', 'error', err);
                                                        })
                                                    }}
                                                    >
                                                        <Clear fontSize="small" color="error" />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    )}                                          
                                </ListItem>
                            )
                        })}
                        <ListItem
                        className="list-item"
                        >
                            <Link className='show-all' to='/profil/notifications'>Zobrazit všechna upozornění</Link>
                        </ListItem>
                    </React.Fragment>
                ) : (
                    <ListItem>
                        Nemáte žádné upozornění
                    </ListItem>
                )}
            </List>
        </div>
        )
    }
}

export default withSnackbar(connect()(NotificationsList));