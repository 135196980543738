import { SET_SPORT_DETAIL_ADDRESS_FILTER, SET_DATE_FROM_FILTER, SET_DATE_TO_FILTER, SET_RADIUS_FILTER, 
    SET_SPORTS_ARRAY_FILTER, SET_SPORTSMEN_ARRAY_FILTER, SET_SPORTS_DISTANCE, SET_SPORTS_ORDER,
    SET_SPORT_USER_TYPE_FILTER } from '../actions/officialSportsFilterActions';
import moment from 'moment';

// initial state for official filters
const officialSportsFiltersInitialState = {
    sportsmenArray: [],
    address: {        
        sportDetail: {
            name: '',
            longitude: 0,
            latitude: 0,
            zoom: 0
        }
    },
    dateFrom: moment().locale('sk'),
    dateTo: moment().add(10, 'days'),
    radius: 0,
    sportsArray: '',
    distance: 0,
    order: 'time',
    sportAction: 'match'
};

export const sportsOfficialFiltersReducer = (state = officialSportsFiltersInitialState, action) => {
    switch(action.type){
        case SET_SPORTSMEN_ARRAY_FILTER:
            return {
                ...state,
                sportsmenArray: action.sportsmenArray
            }       

        case SET_SPORT_DETAIL_ADDRESS_FILTER:
            return {
                ...state,
                address: {
                    ...state.address,
                    sportDetail: {
                        ...state.address.sportDetail,
                        ...action.address
                    }                   
                }
            }

        case SET_DATE_FROM_FILTER:
            return {
                ...state,
                dateFrom: action.dateFrom
            }

        case SET_DATE_TO_FILTER:
            return {
                ...state,
                dateTo: action.dateTo
            }

        case SET_RADIUS_FILTER:
            return {
                ...state,
                radius: action.radius
            }

        case SET_SPORTS_ARRAY_FILTER:
            return {
                ...state,
                sportsArray: action.sportsArray
            }

        case SET_SPORTS_DISTANCE:
            return {
                ...state,
                distance: action.sportsDistance
            }

        case SET_SPORTS_ORDER:
            return {
                ...state,
                order: action.order
            }

        case SET_SPORT_USER_TYPE_FILTER:
            return {
                ...state,
                sportAction: action.sportAction
            }

        default:
            return state;
    }    
}