import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import { formatNumber } from '../../../helper_func/Format';
import { startAcceptUserToMyEvent, startRejectUserToMyEvent } from '../../../actions/usersActions';
import Wave from '../../animations/Wave';
import { withSnackbar } from 'notistack';
import { showSnackbar } from '../../../helper_func/SnackBarcCallback';

class NotificationsDialog extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            joining: false
        }

        this.handleAcceptUser = this.handleAcceptUser.bind(this);
        this.handleRejectUser = this.handleRejectUser.bind(this);
    }

    handleAcceptUser() {
        const { notificationID, userID, handleCloseDialog, userCurInfo, access } = this.props;
        this.setState({
            joining: true
        });

        startAcceptUserToMyEvent(notificationID, userID, access, userCurInfo.SF_USER_UID, {
            about: 'ACCEPT',
            access,
            name: userCurInfo.SF_USER_NAME,
            photo: userCurInfo.SF_USER_IMAGE_URL,
            userID: userCurInfo.SF_USER_UID
        }).then(() => {
            handleCloseDialog();
        }).catch((err) => {
            const status = err && err.status ? err.status : '';
            const msg = err && err.msg ? err.msg : '';

            const snackMsg = status && msg ? msg : 'Nastala chyba při potvrzení uživatele !';
            if (!status && !msg) {
                this.setState({
                    joining: false
                });
            }
            showSnackbar(this.props.enqueueSnackbar, this.props.closeSnackbar, snackMsg, 'error', err);
        })  
    }

    handleRejectUser() {
        const { notificationID, userID, userCurInfo, handleCloseDialog, access } = this.props;
        this.setState({
            joining: true
        });
        startRejectUserToMyEvent(userID, notificationID, access).then(() => {
            handleCloseDialog();
        }).catch((err) => {
            this.setState({
                joining: false
            });
            showSnackbar(this.props.enqueueSnackbar, this.props.closeSnackbar, 'Nastala chyba při odstránení uživatele !', 'error', err);
        })
    }


    render() {
        const { openDialogID, handleCloseDialog, notificationID } = this.props;
        
      return (
        <Dialog
        fullScreen
        className="notifications-dialog"
        open={openDialogID === notificationID}
        onClose={handleCloseDialog}
        >
            <AppBar position="relative" className="notifications-dialog-appbar">
                    <Toolbar variant="dense" className="notifications-dialog-toolbar">
                        <h3 className="notifications-dialog-title">
                            Potvrzení uživatele
                        </h3>
                        <IconButton color="inherit" onClick={handleCloseDialog} aria-label="Close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
            </AppBar>
            <DialogContent className="notifications-dialog-cont">
                <div className="notifications-dialog-wrapper">
                    <div className="notifications-dialog-wrapper--media">
                        {this._renderPhoto()}
                        {this._renderActions()}
                    </div> 
                    <div className="notifications-dialog-wrapper--text">
                        {this._renderContent()}
                    </div>   
                </div>       
            </DialogContent>            
        </Dialog>
      )
    }

    _renderPhoto() {
        const { photo, name } = this.props;

        return (
            <div className="notifications-dialog-wrapper--photo">
                <img src={photo} alt={name} />
            </div>
        )
    }

    _renderContent() {
        const { name, userID, message, eventName, notificationID, contactInfo, handleCloseDialog, handleCloseDialogWrapper = () => {} } = this.props;

        return (
            <div className="notifications-dialog-wrapper--info">
                <h4>
                    Názov události: <Link onClick={() => {
                            handleCloseDialog()
                            handleCloseDialogWrapper()
                        }} to={`/event/detail/${notificationID}`}>{eventName}</Link>
                </h4>
                <p><Link onClick={() => {
                    handleCloseDialog()
                    handleCloseDialogWrapper()
                }} to={`/user/${userID}`}>{name}</Link> by se rád přidal k vaší události !</p>
                <p><span className="notifications-dialog-wrapper--info-message">Zpráva:</span> {message}</p>
                {contactInfo && (
                    <div>
                        <h4 className="notifications-dialog-wrapper--info-contact">Kontaktuj mě:</h4>
                        {contactInfo.email && (
                            <p><span className="notifications-dialog-wrapper--info-provider">Email:</span> <a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a></p>
                        )}
                        {contactInfo.messenger && (
                            <p><span className="notifications-dialog-wrapper--info-provider">Messenger:</span> {contactInfo.messenger}</p>
                        )}
                        {contactInfo.phone && (
                            <p><span className="notifications-dialog-wrapper--info-provider">Mobil:</span> <a href={`tel:${contactInfo.phone}`}>{formatNumber(contactInfo.phone)}</a></p>
                        )}
                    </div>
                )}
            </div>
        )
    }

    _renderActions() {  
        const { joining } = this.state;        
        
        return (
            <div className="notifications-dialog-wrapper--actions">
                {!joining ? (
                    <React.Fragment>
                        <Button color="primary" variant="contained" onClick={this.handleAcceptUser}> 
                            Přijat
                        </Button>
                        <Button onClick={this.handleRejectUser} variant="contained" className="notifications-dialog-wrapper--actions-reject">
                            Odstranit
                        </Button>
                    </React.Fragment>
                ) : (
                    <Wave />
                )}                
            </div>
        )
    }
}

export default withSnackbar(NotificationsDialog);