import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Clear from '@material-ui/icons/Clear';
import ReactGA from 'react-ga';

/**
 * Funkcia zobrazi snackbar
 * TODO napojit na google analytics
 * 
 * @param {Func} enqueSnackbar funkcia, ktora zobrazi snackbar
 * @param {Function} closeSnackbar funkcia, ktora zavrie snackbar
 * @param {String} message sprava v snackbare
 * @param {String} variant varianta snackbaru
 * @param {Object} errorObject catch error object
 * @param {Boolean} isError ci sa jedna o block catch alebo then
 */
export const showSnackbar = (enqueSnackbar, closeSnackbar, message, variant, errorObject = {}, isError = true) => {
    if (isError) {
        let labelName = ''
        if (errorObject.message) {
            labelName = errorObject.message
        } else if (errorObject.msg) {
            labelName = errorObject.msg
        } else if (typeof errorObject === 'string') {
            labelName = errorObject
        } else {
            labelName = 'js error'
        }

        let { stack } = errorObject
        stack = JSON.stringify(stack)

        const params = errorObject.getParams ? errorObject.getParams() : {};

        ReactGA.event({
            category: 'JS ERROR',
            action: message,
            label: `stack: ${stack}, params: ${JSON.stringify(params)}, label: ${labelName}, userAgent: ${navigator.userAgent}`
        })
    } else {
        ReactGA.event({
            category: 'JS SUCCESS',
            action: message,
            label: 'Success'
        })
    }

    enqueSnackbar(message, {
        variant,
        action: () => (
            <IconButton onClick={() => {
                closeSnackbar()
            }}>
                <Clear />
            </IconButton>
        )   
    })
}