import React from 'react';
import { Link } from 'react-router-dom';

const divStyle = {
    flexGrow: 1
  };

const Logo = () => (

    <div style={divStyle} className="header__logo" >
        <Link to="/" >
            <img src="/images/logo/sf_logo.svg" alt="logo" className="header__logo-image" /> 
            <h2 className="header__logo-heading">
                SportyFind
            </h2>    
        </Link>
    </div>

);

export default Logo;