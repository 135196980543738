import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRouteLogout = ({ isAuthenticated, component: Component, ...rest }) => (    
   <Route {...rest} component={(props) => (
       isAuthenticated ? (
            <Redirect to='/' />
       ) : (
            <Component {...props} />
       )
   )} />   
);

const mapStateToProps = (state) => ({
    isAuthenticated: !!state.user.userInfo.SF_USER_UID
});

export default connect(mapStateToProps)(PrivateRouteLogout);