import React from 'react';
import WifiOff from '@material-ui/icons/WifiOff';

const CLASSNAME = 'offline';

const Offline = () => {

    return (
        <div className={CLASSNAME}>
            <div className={`${CLASSNAME}__image`}>
                <WifiOff fontSize='inherit' color='error' />                
            </div>
            <div className={`${CLASSNAME}__title`}>Vypadá to, že ste offline</div>
            <div className={`${CLASSNAME}__subtitle`}>Zkontrolujte připojení k vašemu internetu, jakmile budete znovu online, webová stránka sportyfind.cz se vám znovu načte.</div>
        </div>
    )
}

export default React.memo(Offline);