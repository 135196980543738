import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Logo from './Logo';
import LogoMobile from './mobile_components/LogoMobile';
import Navigation from './Navigation';
import NavigationMobile from './mobile_components/NavigationMobile';
import User from './User';
import Hidden from '@material-ui/core/Hidden';
import withWidth from '@material-ui/core/withWidth';

class Header extends React.PureComponent {
    constructor(props){
        super(props);
    }

    render(){
        return (
            <div className="header">
                <AppBar position="static">
                    <Toolbar>
                        <Hidden smUp>
                            <NavigationMobile />
                        </Hidden>
                        <Hidden smUp>
                            <LogoMobile />
                        </Hidden>
                        <Hidden xsDown>
                            <Logo />
                        </Hidden>
                        <Hidden xsDown>
                            <Navigation />
                        </Hidden>
                        <User />
                    </Toolbar>
                </AppBar>
                
            </div>
        )
    }
}

export default withWidth()(Header);