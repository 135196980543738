import React from 'react';
import { Link } from 'react-router-dom';
import Love from '@material-ui/icons/Favorite';

class Footer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="footer">
                <div className="footer__contact">
                    <div className="footer__contact-legal">
                        <Link to='/contact'>Kontakt</Link> 
                        <Link to='/privacy-policy' >Ochrana osobních údajů</Link>                      
                    </div>
                    <div className="footer__contact-legal">
                        <div>App icons by <a className="footer__contact-link" target="_blank" rel="noopener noreferrer" href="https://icons8.com/">icons8</a></div>
                    </div>
                </div>
                <div className="footer__rights">
                    <div>&#64; SPORTYFIND</div>
                    <div className="footer__rights-text">Made with <Love color="error" style={{marginLeft: '3px', marginRight: '3px'}} /> for sport.</div>
                </div>
            </div>
        )
    }
}

export default Footer;