import { createStore, combineReducers, applyMiddleware } from 'redux';
import { LOGOUT } from '../actions/auth';
import thunk from 'redux-thunk';
import { sportsOfficialReducer } from '../reducers/officialSportsReducer';
import { sportsOfficialFiltersReducer } from '../reducers/officialSportsFilterReducer';
import { usersReducer } from '../reducers/usersReducer';
import { usersSportsFiltersReducer } from '../reducers/usersSportsFilterReducer';
import { usersSportsReducer } from '../reducers/usersSportsReducer';
import { centrumsFilterReducer } from '../reducers/centrumsFilterReducer';
import { centrumsReducer } from '../reducers/centrumsReducer';
import { clubsReducer } from '../reducers/clubsReducer';
import { clubsFilterReducer } from '../reducers/clubsFilterReducer';

const reducers = combineReducers({
    officialSports: sportsOfficialReducer,
    officialSportsFilter: sportsOfficialFiltersReducer,
    user: usersReducer,
    userSports: usersSportsReducer,
    usersSportsFilter: usersSportsFiltersReducer,
    centrumsFilter: centrumsFilterReducer,
    centrums: centrumsReducer,
    clubs: clubsReducer,
    clubsFilter: clubsFilterReducer
});

const rootReducer = ( state, action ) => {
    if ( action.type === LOGOUT ) {
        state = undefined;
    }

    return reducers(state, action)
}

let configureStore
if (__IS_PRODUCTION__) {
    configureStore = createStore(
        rootReducer,
        applyMiddleware(thunk)
    );
} else {
    configureStore = createStore(
        rootReducer,
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
        applyMiddleware(thunk)
    );
}

export  {
    configureStore
}