
// filters actions
export const SET_USER_ADDRESS_FILTER = 'SET_USER_ADDRESS_FILTER';
export const SET_USER_DATE_FROM_FILTER = 'SET_USER_DATE_FROM_FILTER';
export const SET_USER_DATE_TO_FILTER = 'SET_USER_DATE_TO_FILTER';
export const SET_USER_RADIUS_FILTER = 'SET_USER_RADIUS_FILTER';
export const SET_USER_SPORTS_ARRAY_FILTER = 'SET_USER_SPORTS_ARRAY_FILTER';
export const SET_USER_SPORTS_DISTANCE = 'SET_USER_SPORTS_DISTANCE';
export const SET_USER_ORDER_FILTER = 'SET_USER_ORDER_FILTER';

// actions
export const setUserAddressFilter = ( address = { name: '', longitude: 14.47, latitude: 50.07, zoom: 4, defined: false } ) => ({
    type: SET_USER_ADDRESS_FILTER,
    address
});

export const setUserDateFromFilter = (dateFrom) => ({
    type: SET_USER_DATE_FROM_FILTER,
    dateFrom
});

export const setUserDateToFilter = (dateTo) => ({
    type: SET_USER_DATE_TO_FILTER,
    dateTo
});

export const setUserRadiusFilter = ( radius = 10 ) => ({
    type: SET_USER_RADIUS_FILTER,
    radius
});

export const setUserSportsArrayFilter = ( sportsArray ) => ({
    type: SET_USER_SPORTS_ARRAY_FILTER,
    sportsArray
});

export const setUserSportsDistance = ( sportsDistance = 0 ) => ({
    type: SET_USER_SPORTS_DISTANCE,
    sportsDistance
});

export const setUserOrderFilter = ( order = 'time' ) => ({
    type: SET_USER_ORDER_FILTER,
    order
});