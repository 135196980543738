import React from 'react';
import { history } from '../../routers/AppRouter';
import ReactGA from 'react-ga';

class ErrorBoundary extends React.PureComponent {
    constructor(props) {
        super(props);
        
        this.state = { 
            hasError: false 
        };

        this.logErrorToAnalytics = this.logErrorToAnalytics.bind(this);
    }
  
    componentDidCatch(error, info) {
      // Display fallback UI
      this.setState({ hasError: true });
      this.logErrorToAnalytics(error)
      history.push('/500-internal-error')
    }
  
    render() { 
        return this.props.children;
    }

    logErrorToAnalytics(error) {
        let { stack } = error
        stack = JSON.stringify(stack)
        const params = error.getParams ? error.getParams() : {};
        
        ReactGA.event({
            category: 'INTERNAL ERROR',
            action: error.toString(),
            label: `stack: ${stack}, params: ${JSON.stringify(params)}, userAgent: ${navigator.userAgent}`
        })
    }
}

export default ErrorBoundary;