import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

var config = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.FIREBASE_DATABASE_URL,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID
  };

  firebase.initializeApp(config);

  const database = firebase.firestore();
  const region = __IS_PRODUCTION__ ? 'europe-west1' : 'us-central1';
  const functions = firebase.app().functions(region);
  
  const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
  const passwordAuthProvider = new firebase.auth.EmailAuthProvider();
  const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();


  export { firebase, functions, googleAuthProvider, passwordAuthProvider, facebookAuthProvider, database as default };
