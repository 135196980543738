
// filters actions
export const SET_SPORTSMEN_ARRAY_FILTER = 'SET_SPORTSMEN_ARRAY_FILTER';
export const SET_SPORT_DETAIL_ADDRESS_FILTER = 'SET_SPORT_DETAIL_ADDRESS_FILTER';
export const SET_DATE_FROM_FILTER = 'SET_DATE_FROM_FILTER';
export const SET_DATE_TO_FILTER = 'SET_DATE_TO_FILTER';
export const SET_RADIUS_FILTER = 'SET_RADIUS_FILTER';
export const SET_SPORTS_ARRAY_FILTER = 'SET_SPORTS_ARRAY_FILTER';
export const SET_SPORTS_DISTANCE = 'SET_SPORTS_DISTANCE';
export const SET_SPORTS_ORDER = 'SET_SPORTS_ORDER';
export const SET_SPORT_USER_TYPE_FILTER = 'SET_SPORT_USER_TYPE_FILTER';

// actions
export const setSportsmenArrayFilter = (sportsmenArray = []) => ({
    type: SET_SPORTSMEN_ARRAY_FILTER,
    sportsmenArray
});

export const setSportDetailAddressFilter = ( address = { name: '', longitude: 0, latitude: 0, zoom: 6 } ) => ({
    type: SET_SPORT_DETAIL_ADDRESS_FILTER,
    address
});

export const setDateFromFilter = (dateFrom) => ({
    type: SET_DATE_FROM_FILTER,
    dateFrom
});

export const setDateToFilter = (dateTo) => ({
    type: SET_DATE_TO_FILTER,
    dateTo
});

export const setRadiusFilter = ( radius = 0 ) => ({
    type: SET_RADIUS_FILTER,
    radius
});

export const setSportsArrayFilter = ( sportsArray ) => ({
    type: SET_SPORTS_ARRAY_FILTER,
    sportsArray
});

export const setSportsDistance = ( sportsDistance = 0 ) => ({
    type: SET_SPORTS_DISTANCE,
    sportsDistance
});

export const setSportsOrder = ( order = 'time' ) => ({
    type: SET_SPORTS_ORDER,
    order
});

export const setSportUserTypeFilter = (sportAction = 'match') => ({
    type: SET_SPORT_USER_TYPE_FILTER,
    sportAction
})