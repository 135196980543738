export const FETCH_CLUBS_BEGGINS = 'FETCH_CLUBS_BEGGINS';
export const FETCH_CLUBS_SUCCESS = 'FETCH_CLUBS_SUCCESS';
export const FETCH_CLUBS_FAILED = 'FETCH_CLUBS_FAILED';

export const fetchclubsBegin = () => ({
    type: FETCH_CLUBS_BEGGINS
});

export const fetchclubsSuccess = ( clubs ) => ({
    type: FETCH_CLUBS_SUCCESS,
    payload: { clubs }
});

export const fetchclubsFailed = (error) => ({
    type: FETCH_CLUBS_FAILED,
    payload: { error }
})