import database from '../firebase/firebase';
import { firebaseEventsLimit, placeholdersValue, sportsUserAvailibleApi } from '../constants/constants';
import { apiValueGenerator } from '../api_func/apiConvertor';

export const GET_PUBLIC_EVENTS = 'GET_PUBLIC_EVENTS';
export const RESET_EVENTS = 'RESET_EVENTS';
export const RESET_PRIVATE_EVENTS = 'RESET_PRIVATE_EVENTS';
export const GET_USERS_SPORT_ACTIONS = 'GET_USERS_SPORT_ACTIONS';

const FRIEND_EVENTS_COL = 'friend_events';

/**
 * USER NOT LOGIN
 */

/**
 * Dispatch action pre verejne udalosti
 * @param {Array} events Pole udalosti = verejnych
 */
export const getPublicEvents = ( events ) => ({
    type: GET_PUBLIC_EVENTS,
    events
});

/**
 * Dispatch action pre resetovanie udalosti = vsetkych
 */
export const resetEvents = () => ({
    type: RESET_EVENTS
});

/**
 * Dispatch action pre nastavenie sportovych udalosti
 * @param {Array} events Pole udalosti - sportove
 */
export const getUsersActionsEvents = ( events ) => ({
    type: GET_USERS_SPORT_ACTIONS,
    events
});

/**
 * Vygenereruje pole udalosti pre placeholdery
 * @param {Number} number Pocet placeholderov ktore chceme generovat
 */
export const generatePlaceholders = (number = placeholdersValue) => {
    const placeholderData = {
        access: '',
        date: null,
        info: '',
        type: '',
        sportUserDistance: 0,
        description: '',
        address: {
            name: '',
            longitude: '',
            latitude: ''
        }
    }
    let events = []

    for ( var i = 0; i < number; i++) {
        events.push({
            eventId: i,
            ...placeholderData
        })
    }

    return events;
}

/**
 * @function startGetPublicSports
 * @returns vracia sporty podla filtru
 * @param {Object} hashes url parametre
 */
export const startGetPublicSports = ( hashes ) => {
    const sportDBValue = hashes.type ? apiValueGenerator(hashes.type, sportsUserAvailibleApi) : '';

    // FILTER: date from
    if (hashes['date-from'] && !hashes['date-to'] && !hashes.type) {
        const momentValue = hashes['date-from'];
        return database.collection('events').doc('public').collection(FRIEND_EVENTS_COL)
                .orderBy('date').where('date', '>=', momentValue).limit(firebaseEventsLimit).get();
                
    // FILTER: date from, date to
    } else if (hashes['date-from'] && hashes['date-to'] && !hashes.type) {
        const momentValueFrom = hashes['date-from'];
        const momentValueTo = hashes['date-to'];
        return database.collection('events').doc('public').collection(FRIEND_EVENTS_COL)
                .orderBy('date').where('date', '>=', momentValueFrom).where('date', '<=', momentValueTo).limit(firebaseEventsLimit).get();

    // FILTER: date to
    } else if (hashes['date-to'] && !hashes['date-from'] && !hashes.type) {
        const momentValueTo = hashes['date-to'];
        return database.collection('events').doc('public').collection(FRIEND_EVENTS_COL)
                .orderBy('date').where('date', '<=', momentValueTo).limit(firebaseEventsLimit).get();

    // FILTER: date from a type
    } else if (hashes['date-from'] && hashes.type && !hashes['date-to']) {
        const momentValueFrom = hashes['date-from'];
        return database.collection('events').doc('public').collection(FRIEND_EVENTS_COL)
                .orderBy('date').where('date', '>=', momentValueFrom).where('type', '==', sportDBValue).limit(firebaseEventsLimit).get();

    // FILTER: date to, type
    } else if (hashes['date-to'] && hashes.type && !hashes['date-from']) {
        const momentValueTo = hashes['date-to'];
        return database.collection('events').doc('public').collection(FRIEND_EVENTS_COL)
                .orderBy('date').where('date', '<=', momentValueTo).where('type', '==', sportDBValue).limit(firebaseEventsLimit).get();

    // FILTER date from, date to a type
    } else if (hashes['date-from'] && hashes['date-to'] && hashes.type) {
        const momentValueFrom = hashes['date-from'];
        const momentValueTo = hashes['date-to'];
        return database.collection('events').doc('public').collection(FRIEND_EVENTS_COL)
                .orderBy('date').where('date', '>=', momentValueFrom).where('date', '<=', momentValueTo).where('type', '==', sportDBValue).limit(firebaseEventsLimit).get();

    // FILTER: type
    } else if (hashes.type && !hashes['date-from'] && !hashes['date-to']) {
        return database.collection('events').doc('public').collection(FRIEND_EVENTS_COL)
                .where('type', '==', sportDBValue).limit(firebaseEventsLimit).get();

    // FILTER: none
    } else {
        return database.collection('events').doc('public').collection(FRIEND_EVENTS_COL).orderBy('date').limit(firebaseEventsLimit).get();
    }
}

/**
 * @function startGetPublicSportsPagination
 * @returns vracia strankovanie sportov podla filtru
 * @param {Object} hashes url parametre
 * @param {Object} lastKey doc reference posledneho sportu v databaze
 */
export const startGetPublicSportsPagination = ( hashes, lastKey ) => {
    const sportDBValue = hashes.type ? apiValueGenerator(hashes.type, sportsUserAvailibleApi) : '';

    // FILTER: date from
    if (hashes['date-from'] && !hashes['date-to'] && !hashes.type) {
        const momentValue = hashes['date-from'];
        return database.collection('events').doc('public').collection(FRIEND_EVENTS_COL)
                .orderBy('date').where('date', '>=', momentValue).startAfter(lastKey).limit(firebaseEventsLimit).get();
                
    // FILTER: date from, date to
    } else if (hashes['date-from'] && hashes['date-to'] && !hashes.type) {
        const momentValueFrom = hashes['date-from'];
        const momentValueTo = hashes['date-to'];
        return database.collection('events').doc('public').collection(FRIEND_EVENTS_COL)
                .orderBy('date').where('date', '>=', momentValueFrom).where('date', '<=', momentValueTo).startAfter(lastKey).limit(firebaseEventsLimit).get();

    // FILTER: date to
    } else if (hashes['date-to'] && !hashes['date-from'] && !hashes.type) {
        const momentValueTo = hashes['date-to'];
        return database.collection('events').doc('public').collection(FRIEND_EVENTS_COL)
                .orderBy('date').where('date', '<=', momentValueTo).startAfter(lastKey).limit(firebaseEventsLimit).get();

    // FILTER: dateFrom a type
    } else if (hashes['date-from'] && hashes.type && !hashes['date-to']) {
        const momentValueFrom = hashes['date-from'];
        return database.collection('events').doc('public').collection(FRIEND_EVENTS_COL)
                .orderBy('date').where('date', '>=', momentValueFrom).where('type', '==', sportDBValue).startAfter(lastKey).limit(firebaseEventsLimit).get();

    // FILTER: date to, type
    } else if (hashes['date-to'] && hashes.type && !hashes['date-from']) {
        const momentValueTo = hashes['date-to'];
        return database.collection('events').doc('public').collection(FRIEND_EVENTS_COL)
                .orderBy('date').where('date', '<=', momentValueTo).where('type', '==', sportDBValue).startAfter(lastKey).limit(firebaseEventsLimit).get();

    // FILTER date from, date to a type
    } else if (hashes['date-from'] && hashes['date-to'] && hashes.type) {
        const momentValueFrom = hashes['date-from'];
        const momentValueTo = hashes['date-to'];
        return database.collection('events').doc('public').collection(FRIEND_EVENTS_COL)
                .orderBy('date').where('date', '>=', momentValueFrom).where('date', '<=', momentValueTo).where('type', '==', sportDBValue).startAfter(lastKey).limit(firebaseEventsLimit).get();

    // FILTER: type
    } else if (hashes.type && !hashes['date-from'] && !hashes['date-to']) {
        return database.collection('events').doc('public').collection(FRIEND_EVENTS_COL)
                .where('type', '==', sportDBValue).startAfter(lastKey).limit(firebaseEventsLimit).get();

    // FILTER: none
    } else {
        return database.collection('events').doc('public').collection(FRIEND_EVENTS_COL).orderBy('date').startAfter(lastKey).limit(firebaseEventsLimit).get();
    }
}

/**
 * Vrati prvych {limit} sportovych udalosti
 * @param {Object} hashes Url parametre
 */
export const startGetUsersActionsEvents = (hashes) => {
    const sportDBValue = hashes.type ? apiValueGenerator(hashes.type, sportsUserAvailibleApi) : '';

    // FILTER: date from
    if (hashes['date-from'] && !hashes['date-to'] && !hashes.type) {
        const momentValue = hashes['date-from'];
        return database.collection('events').doc('actions').collection('sport_actions')
                .orderBy('date').where('date', '>=', momentValue).limit(firebaseEventsLimit).get();
                
    // FILTER: date from, date to
    } else if (hashes['date-from'] && hashes['date-to'] && !hashes.type) {
        const momentValueFrom = hashes['date-from'];
        const momentValueTo = hashes['date-to'];
        return database.collection('events').doc('actions').collection('sport_actions')
                .orderBy('date').where('date', '>=', momentValueFrom).where('date', '<=', momentValueTo).limit(firebaseEventsLimit).get();

    // FILTER: date to
    } else if (hashes['date-to'] && !hashes['date-from'] && !hashes.type) {
        const momentValueTo = hashes['date-to'];
        return database.collection('events').doc('actions').collection('sport_actions')
                .orderBy('date').where('date', '<=', momentValueTo).limit(firebaseEventsLimit).get();

    // FILTER: date from a type
    } else if (hashes['date-from'] && hashes.type && !hashes['date-to']) {
        const momentValueFrom = hashes['date-from'];
        return database.collection('events').doc('actions').collection('sport_actions')
                .orderBy('date').where('date', '>=', momentValueFrom).where('type', '==', sportDBValue).limit(firebaseEventsLimit).get();

    // FILTER: date to, type
    } else if (hashes['date-to'] && hashes.type && !hashes['date-from']) {
        const momentValueTo = hashes['date-to'];
        return database.collection('events').doc('actions').collection('sport_actions')
                .orderBy('date').where('date', '<=', momentValueTo).where('type', '==', sportDBValue).limit(firebaseEventsLimit).get();

    // FILTER date from, date to a type
    } else if (hashes['date-from'] && hashes['date-to'] && hashes.type) {
        const momentValueFrom = hashes['date-from'];
        const momentValueTo = hashes['date-to'];
        return database.collection('events').doc('actions').collection('sport_actions')
                .orderBy('date').where('date', '>=', momentValueFrom).where('date', '<=', momentValueTo).where('type', '==', sportDBValue).limit(firebaseEventsLimit).get();

    // FILTER: type
    } else if (hashes.type && !hashes['date-from'] && !hashes['date-to']) {
        return database.collection('events').doc('actions').collection('sport_actions')
                .where('type', '==', sportDBValue).limit(firebaseEventsLimit).get();

    // FILTER: none
    } else {
        return database.collection('events').doc('actions').collection('sport_actions').orderBy('date').limit(firebaseEventsLimit).get();
    }
}

/**
 * Vrati dalsich {limit} udalosti na zaklade parametrov
 * @param {Object} hashes Url parametre
 * @param {Object} lastKey Doc poslednej nacitanej sportovej udalosti
 */
export const startGetUsersActionsEventsPagination = ( hashes, lastKey ) => {
    const sportDBValue = hashes.type ? apiValueGenerator(hashes.type, sportsUserAvailibleApi) : '';
    
    // FILTER: date from
    if (hashes['date-from'] && !hashes['date-to'] && !hashes.type) {
        const momentValue = hashes['date-from'];
        return database.collection('events').doc('actions').collection('sport_actions')
                .orderBy('date').where('date', '>=', momentValue).startAfter(lastKey).limit(firebaseEventsLimit).get();
                
    // FILTER: date from, date to
    } else if (hashes['date-from'] && hashes['date-to'] && !hashes.type) {
        const momentValueFrom = hashes['date-from'];
        const momentValueTo = hashes['date-to'];
        return database.collection('events').doc('actions').collection('sport_actions')
                .orderBy('date').where('date', '>=', momentValueFrom).where('date', '<=', momentValueTo).startAfter(lastKey).limit(firebaseEventsLimit).get();

    // FILTER: date to
    } else if (hashes['date-to'] && !hashes['date-from'] && !hashes.type) {
        const momentValueTo = hashes['date-to'];
        return database.collection('events').doc('actions').collection('sport_actions')
                .orderBy('date').where('date', '<=', momentValueTo).startAfter(lastKey).limit(firebaseEventsLimit).get();

    // FILTER: date from a type
    } else if (hashes['date-from'] && hashes.type && !hashes['date-to']) {
        const momentValueFrom = hashes['date-from'];
        return database.collection('events').doc('actions').collection('sport_actions')
                .orderBy('date').where('date', '>=', momentValueFrom).where('type', '==', sportDBValue).startAfter(lastKey).limit(firebaseEventsLimit).get();

    // FILTER: date to, type
    } else if (hashes['date-to'] && hashes.type && !hashes['date-from']) {
        const momentValueTo = hashes['date-to'];
        return database.collection('events').doc('actions').collection('sport_actions')
                .orderBy('date').where('date', '<=', momentValueTo).where('type', '==', sportDBValue).startAfter(lastKey).limit(firebaseEventsLimit).get();

    // FILTER date from, date to a type
    } else if (hashes['date-from'] && hashes['date-to'] && hashes.type) {
        const momentValueFrom = hashes['date-from'];
        const momentValueTo = hashes['date-to'];
        return database.collection('events').doc('actions').collection('sport_actions')
                .orderBy('date').where('date', '>=', momentValueFrom).where('date', '<=', momentValueTo).where('type', '==', sportDBValue).startAfter(lastKey).limit(firebaseEventsLimit).get();

    // FILTER: type
    } else if (hashes.type && !hashes['date-from'] && !hashes['date-to']) {
        return database.collection('events').doc('actions').collection('sport_actions')
                .where('type', '==', sportDBValue).startAfter(lastKey).limit(firebaseEventsLimit).get();

    // FILTER: none
    } else {
        return database.collection('events').doc('actions').collection('sport_actions').orderBy('date').startAfter(lastKey).limit(firebaseEventsLimit).get();
    }
}