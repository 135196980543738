// sports actions
export const FETCH_OFFICIAL_SPORTS_BEGGINS = 'FETCH_OFFICIAL_SPORTS_BEGGINS';
export const FETCH_OFFICIAL_SPORTS_SUCCESS = 'FETCH_OFFICIAL_SPORTS_SUCCESS';
export const FETCH_OFFICIAL_SPORTS_FAILED = 'FETCH_OFFICIAL_SPORTS_FAILED';

export const FETCH_OFFICIAL_DETAIL_BEGGINS = 'FETCH_OFFICIAL_DETAIL_BEGGINS';
export const FETCH_OFFICIAL_DETAIL_SUCCESS = 'FETCH_OFFICIAL_DETAIL_SUCCESS';
export const FETCH_OFFICIAL_DETAIL_FAILED = 'FETCH_OFFICIAL_DETAIL_FAILED';
export const FETCH_OFFICIAL_DETAIL_RESET = 'FETCH_OFFICIAL_DETAIL_RESET'

/*ACTIONS*/
export const fetchOfficialSportsBegin = () => ({
    type: FETCH_OFFICIAL_SPORTS_BEGGINS
});

export const fetchOfficialSportsSuccess = (sports) => ({
    type: FETCH_OFFICIAL_SPORTS_SUCCESS,
    payload: { sports }
});

export const fetchOfficialSportsFailed = (error) => ({
    type: FETCH_OFFICIAL_SPORTS_FAILED,
    payload: { error }
});

// fetch detail
export const fetchDetailBeggins = () => ({
    type: FETCH_OFFICIAL_DETAIL_BEGGINS
});

export const fetchDetailSuccess = ( sport ) => ({
    type: FETCH_OFFICIAL_DETAIL_SUCCESS,
    payload: { sport }
});

export const fetchDetailFailed = ( error ) => ({
    type: FETCH_OFFICIAL_DETAIL_FAILED,
    payload: { error }
})

export const resetDetail = () => ({
    type: FETCH_OFFICIAL_DETAIL_RESET
});