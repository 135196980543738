import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import PaginationFirestore from '../pagination/PaginationFirestore';
import Avatar from '@material-ui/core/Avatar';
import { Link } from 'react-router-dom';
import { searchUserName, 
        getSearchUsers,
        resetSearchUsers,
        searchUserNamePagination } from '../../actions/usersActions';
import { debounce } from '../../helper_func/Debounce';
import { withSnackbar } from 'notistack';
import { showSnackbar } from '../../helper_func/SnackBarcCallback';
import { usersSearchLimit } from '../../constants/constants';

const styles = theme => ({
    root: {
      width: '100%',
    },   
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing.unit * 2,
      marginLeft: 0,
      width: '100%'
    },
    searchIcon: {
      width: theme.spacing.unit * 9,
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
      width: '100%',
    },
    inputInput: {
      paddingTop: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      paddingLeft: theme.spacing.unit * 10,
      transition: theme.transitions.create('width'),
      width: '100%'
    },
    bigAvatar: {
        width: 50,
        height: 50,
    }   
  });
  

class SearchDialogMobile extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            openDialogID: null,
            searchText: '',
            dataLoaded: false,
            lastVisibleDoc: undefined,
            showLoadMore: true,
            noResults: false
        }

        this._handleInputChange = this._handleInputChange.bind(this);
        this._handleSearch = debounce(this._handleSearch, 800);
        this.getPaginationUsers = this.getPaginationUsers.bind(this);
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount(){
        this.mounted = false;
    }

    render() {
        const { open, handleCloseDialog } = this.props;

      return (
        <Dialog
        fullScreen
        open={open}
        onClose={handleCloseDialog}
        className="notification__dialog"
        >
            <AppBar position="relative" className="notification__dialog-appbar">
                <Toolbar variant="dense" className="notification__dialog-toolbar">
                    {this._renderSearchHeader()}
                    <IconButton color="inherit" onClick={handleCloseDialog} aria-label="Close">
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
          </AppBar>
          <DialogContent className="notification__dialog-content">
                {this._renderSearchResults()}
          </DialogContent>          
        </Dialog>
      )
    }

    _handleInputChange(value) {
        this.setState({
            searchText: value
        })
    }

    _handleSearch(value) {  
        const { searchResults } = this.props;

        let inputValue = value.toLowerCase();
        
        if (searchResults.length > 0) {
            this.props.resetSearchUsers();
        }

        if (inputValue) {
            searchUserName(inputValue).then((snapShot) => {  
                if (this.mounted) {
                    this.setState({
                        showLoadMore: true,
                        noResults: false
                    })
                }

                if (!snapShot.empty) {
                    const lastDoc = snapShot.docs[snapShot.docs.length-1];
                    let usersData = [];
                    snapShot.forEach(function(data) {
                        usersData.push({
                            userID: data.id,
                            ...data.data()
                        })
                    });

                    if (this.mounted) {
                        this.setState({
                            lastVisibleDoc: lastDoc
                        })
                    }
    
                    this.props.getSearchUsers(usersData);
                } else {
                    if (this.mounted) {
                        this.setState({
                            noResults: true
                        })
                    }

                    this.props.getSearchUsers([{
                        name: `Žádné výsledky pro ${value}`,
                        isEmpty: true,
                        userID: 'empty'
                    }])
                }

                if (this.mounted) {
                    this.setState({
                        dataLoaded: true
                    })
                }
            }).catch((err) => {
                showSnackbar(this.props.enqueueSnackbar, this.props.closeSnackbar, 'Nastala chyba při hledání !', 'error', err);
            })
        }        
    } 

    _renderSearchHeader() {
        const { classes } = this.props;
        const { searchText } = this.state;
        
        return (
            <React.Fragment>
                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>
                    <InputBase
                        autoFocus={true}
                        placeholder="Vyhledat přítele..."
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}    
                        onChange={(e) => {
                            this._handleInputChange(e.target.value)
                            this._handleSearch(e.target.value);
                        }} 
                        value={searchText}                       
                    />
                </div>                     
            </React.Fragment>
        )        
    }

    _renderSearchResults() {
        const { searchResults, handleCloseDialog } = this.props;
        const { dataLoaded, showLoadMore, lastVisibleDoc, noResults } = this.state;

        return (
            <List dense>
                {searchResults.length > 0 && searchResults.map((result) => {
                    if (!result.isEmpty) {
                        return (
                            <ListItem
                            key={result.userID}
                            button
                            component={Link}
                            to={`/user/${result.userID}`}
                            onClick={handleCloseDialog}
                            >
                                <ListItemAvatar>
                                    <Avatar
                                        alt={result.name}
                                        src={result.photo}
                                    />
                                </ListItemAvatar>
                                <ListItemText primary={result.name} />
                            </ListItem>
                        )
                    } else {
                        return (
                            <ListItem
                            key={result.userID}
                            >
                                <ListItemText 
                                primary={result.name}
                                />
                            </ListItem> 
                        )
                    }
                })}
                {(dataLoaded && showLoadMore && !noResults) && (
                    <PaginationFirestore 
                    items={searchResults} 
                    itemsLimit={usersSearchLimit}
                    lastVisibleDoc={lastVisibleDoc}
                    paginationData={this.getPaginationUsers} 
                    showLoadMore={showLoadMore}
                    />
                )}
            </List>
        )        
    }

    getPaginationUsers() {
        const { lastVisibleDoc, searchText } = this.state;

        const searchTextValue = searchText.toLowerCase();
        this.setState({
            dataLoaded: false
        });

        if (lastVisibleDoc && searchTextValue) {
            searchUserNamePagination(searchTextValue, lastVisibleDoc).then((queryData) => {
                let usersData = [];
                if (!queryData.empty) {
                    const lastDoc = queryData.docs[queryData.docs.length-1];
                    queryData.forEach(doc => {
                        usersData.push({
                            userID: doc.id,
                            ...doc.data()
                        })
                    })

                    if (this.mounted) {
                        this.setState({
                            lastVisibleDoc: lastDoc
                        })
                    }
                } else {
                    if (this.mounted) {
                        this.setState({
                            showLoadMore: false
                        })
                    }
                }

                this.props.getSearchUsers(usersData);
                if (this.mounted) {
                    this.setState({
                        dataLoaded: true
                    })
                }
            }).catch((err) => {
                showSnackbar(this.props.enqueueSnackbar, this.props.closeSnackbar, 'Nastala chyba při hledání !', 'error', err);
            })
        } else {
            this.setState({
                dataLoaded: true
            });
        }      
    }
}

const mapStateToProps = (state) => {
    return {
        userCurInfo: state.user.userInfo,
        searchResults: state.user.filters.users
    }
}

const mapDispatchToProps = (dispatch) => ({
    getSearchUsers: (usersData) => dispatch(getSearchUsers(usersData)),
    resetSearchUsers: () => dispatch(resetSearchUsers())
})

export default withSnackbar(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SearchDialogMobile)));