import { LOGIN, CHANGE_PHOTO, CHANGE_NAME, ADD_PHONE, ADD_MESSENGER, ADD_PROVIDERS } from '../actions/auth';
import { CREATE_EVENT_ADDRESS, SET_MY_EVENTS, RESET_MY_JOINED_EVENTS_CASHED, SET_EVENT_PARTICIPANTS,
        GET_EVENTS_NOTIFICATIONS, GET_FRIEND_NOTIFICATIONS, RESET_MY_EVENTS_CASHED, USER_ONLINE,
        GET_FRIENDS, SEARCH_USERS, SET_MY_JOINED_EVENTS, RESET_EVENT_PARTICIPANTS, SET_MY_SPORT_EVENTS,
        RESET_MY_SPORT_EVENTS_CASHED, SET_MY_EVENTS_TYPE, RESET_MY_FRIENDS_CASHED, SET_INVITE_FRIEND,
        RESET_INVITE_FRIEND, OPEN_EVENT_NOTIFICATION, CASHED_EVENTS_NOTIFICATIONS, CASHED_FRIEND_EVENTS_NOTIFICATIONS,
        DELETE_EVENT_NOTIFICATION, DELETE_FRIEND_NOTIFICATION, RESET_SEARCH_USERS, REMOVE_INVITE_FRIEND } from '../actions/usersActions';

const initialState = {
    userInfo: {
       SF_USER_UID: '', 
       SF_USER_NAME: '',
       SF_USER_EMAIL: '',       
       SF_USER_IMAGE_URL: '',
       SF_USER_PROVIDERS: [],
       SF_USER_PHONE: '',
       SF_USER_MESSENGER: '',
       SF_USER_FRIENDS_REQUEST: null,
       SF_USER_FRIENDS_REQUEST_WAITING: null       
    },
    event: {
        address: {
            name: '',
            latitude: 50.07,
            longitude: 14.47,
            zoom: 4
        },
        participants: [],
        invitedFriends: []           
    },
    myFriends: [],
    myFriendsCashed: false,
    myEvents: {
        friends: [],
        sport: []
    },
    myEventsType: 'friends',
    myFriendEventsCashed: false,
    mySportEventsCashed: false,
    myJoinedEvents: [],
    myJoinedEventsCashed: false,
    eventsNotifications: [],
    eventsNotificationsCashed: false,
    eventNotificationOpen: false,
    friendNotifications: [],
    friendNotificationsCashed: false,
    friendRequests: [],
    filters: {
        users: []
    },
    online: true
}

export const usersReducer = ( state = initialState, action ) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                userInfo: {
                    SF_USER_UID: action.uid,
                    SF_USER_NAME: action.name,
                    SF_USER_EMAIL: action.email,
                    SF_USER_IMAGE_URL: action.photoURL,
                    SF_USER_PROVIDERS: action.providers,
                    SF_USER_PHONE: action.phone,
                    SF_USER_MESSENGER: action.messenger,                    
                    SF_USER_FRIENDS_REQUEST: action.friendsRequest,
                    SF_USER_FRIENDS_REQUEST_WAITING: action.friendsRequestWaiting
                }
            };

        case CREATE_EVENT_ADDRESS:
            return {
                ...state,
                event: {
                    ...state.event,
                    address:{
                        ...state.event.address,
                        ...action.address
                    }
                }
            } 
            
        case SET_EVENT_PARTICIPANTS:
            return {
                ...state,
                event: {
                    ...state.event,
                    participants: state.event.participants.concat(action.participants)
                }
            }

        case RESET_EVENT_PARTICIPANTS:
            return {
                ...state,
                event: {
                    ...state.event,
                    participants: []
                }
            }

        case SET_INVITE_FRIEND: 
            return {
                ...state,
                event: {
                    ...state.event,
                    invitedFriends: [...action.friend]
                }
            }

        case REMOVE_INVITE_FRIEND:
            return {
                ...state,
                event: {
                    ...state.event,
                    invitedFriends: state.event.invitedFriends.filter(fr => fr.friendID !== action.friend.friendID)
                }
            }

        case RESET_INVITE_FRIEND:
            return {
                ...state,
                event: {
                    ...state.event,
                    invitedFriends: []
                }
            }

        case SET_MY_EVENTS:
            return {
                ...state,
                myEvents: {
                    ...state.myEvents,
                    friends: state.myEvents.friends.concat(action.eventsData)
                },
                myFriendEventsCashed: true
            }   

        case RESET_MY_EVENTS_CASHED:
            return {
                ...state,
                myFriendEventsCashed: action.cashed,
                myEvents: {
                    ...state.myEvents,
                    friends: []
                }
            }

        case SET_MY_EVENTS_TYPE:
            return {
                ...state,
                myEventsType: action.eventType
            }

        case SET_MY_SPORT_EVENTS:
            return {
                ...state,
                myEvents: {
                    ...state.myEvents,
                    sport: state.myEvents.sport.concat(action.eventsData)
                },
                mySportEventsCashed: true
            }

        case RESET_MY_SPORT_EVENTS_CASHED:
            return {
                ...state,
                mySportEventsCashed: action.cashed,
                myEvents: {
                    ...state.myEvents,
                    sport: []
                }
            }
        
        case SET_MY_JOINED_EVENTS:
            return {
                ...state,
                myJoinedEvents: [...action.eventsData]
            }

        case RESET_MY_JOINED_EVENTS_CASHED:
            return {
                ...state,
                myJoinedEventsCashed: action.cashed
            }
            
        case GET_EVENTS_NOTIFICATIONS:
            return {
                ...state,
                eventsNotifications: [...action.notifications]
            }   
            
        case DELETE_EVENT_NOTIFICATION:
            return {
                ...state,
                eventsNotifications: state.eventsNotifications.filter(not => not.notificationID !== action.id)
            }

        case DELETE_FRIEND_NOTIFICATION:
            return {
                ...state,
                friendNotifications: state.friendNotifications.filter(not => not.notificationID !== action.id)
            }
            
        case OPEN_EVENT_NOTIFICATION: 
            return {
                ...state,
                eventNotificationOpen: action.isOpen
            }

        case CASHED_EVENTS_NOTIFICATIONS:
            return {
                ...state,
                eventsNotificationsCashed: action.cashed
            }

        case CASHED_FRIEND_EVENTS_NOTIFICATIONS:
            return {
                ...state,
                friendNotificationsCashed: action.cashed
            }

        case GET_FRIENDS:
            return {
                ...state,
                myFriends: state.myFriends.concat(action.friends),
                myFriendsCashed: true
            }

        case RESET_MY_FRIENDS_CASHED: 
            return {
                ...state,
                myFriendsCashed: action.cashed
            }

        case GET_FRIEND_NOTIFICATIONS:
            return {
                ...state,
                friendNotifications: [...action.notifications]
            }

        case SEARCH_USERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    users: state.filters.users.concat(action.usersData)
                }
            }

        case RESET_SEARCH_USERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    users: []
                }
            }

        case CHANGE_PHOTO:
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    SF_USER_IMAGE_URL: action.photoURL
                }
            }

        case CHANGE_NAME:
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    SF_USER_NAME: action.name
                }
            }

        case ADD_PHONE:
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    SF_USER_PHONE: action.phone
                }
            }

        case ADD_MESSENGER:
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    SF_USER_MESSENGER: action.messenger
                }
            }

        case ADD_PROVIDERS:
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    SF_USER_PROVIDERS: action.providers
                }
            }

        case USER_ONLINE:
            return {
                ...state,
                online: action.online
            }

        default:
            return state
    }
}