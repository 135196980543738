import React from 'react';
import {Link} from 'react-router-dom';
import Footer from '../Footer';
import Button from '@material-ui/core/Button';
import { Helmet } from 'react-helmet';

class ServerError extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
      return (
        <React.Fragment>
          <Helmet>
              <title>Niečo sa pokazilo - chyba serveru.</title>
              <meta name='description' />
              <meta name="robots" content="noindex, nofollow" />
          </Helmet>
          <div className="server-error">
            <div className="server-error__wrapper">
              <div className="server-error__ups">Oops !</div>
              <div className="server-error__info"><span>500</span> - Něco se pokazilo :(</div>
              <Button
              className="server-error__btn"
              variant="contained"
              color="secondary"
              component={Link}
              to="/"
              >
                Pokračovat na hlavní stránku
              </Button>
            </div>
          </div>
          <Footer />
        </React.Fragment>
      )
    }
} 

export default ServerError;